<template>
	<div class="construction-style">
		<div class="search-style">
			<van-field class="choosetime-input" v-model="searchValue" @blur="onRefresh()" clearable left-icon="smile-o"
				placeholder="搜索姓名">
				<template v-slot:left-icon>
					<div style="height: 100%;display: flex;align-items: center;">
						<img src="../../../assets/search.png" class="search-icon" alt="">
					</div>
				</template>
			</van-field>
		</div>
		<filterModel :filterList="filterCondition" @filterFunction="onRefresh" class="filter_style"></filterModel>
		<div class="content-style">
			<pullRefresh ref="pullRefresh" @refresh="onRefresh">
				<van-list class="vant-list-style" v-model="loading" :finished="finished"
					:finished-text="total?'·已加载全部数据·':'·暂无数据·'" @load="onLoad">
					<div class="item-style" v-for="item in list">
						<div class="top" @click="toClientDetail(item)">
							<img src="../../../assets/tx.png" alt="" class="tx-img">
							<div class="top-right">
								<div class="name-style">{{item.consumerName}}</div>
								<div class="linkPhone-style">电话：{{item.phoneNo}}</div>
							</div>
							<img src="../../../assets/xzjt.png" alt="" class="jt-img">
						</div>
						<div class="center">
							<div class="center-top">
								<div>施工合同：{{item.finaleContract}}</div>
								<div v-if="item.startDate">{{item.startDate.slice(0, 10)}}开工</div>
								<div  v-if="item.auditStatus == 4">工期{{item.dateLimit}}天</div>
							</div>
							<div class="title">装修地址：<span class="text"> {{item.detailAddress}}</span></div>
							<div class="title">
								施工状态：
								<span class="text">【{{item.statusCode}}】</span>
							</div>
							<div class="title">施工进度：<span class="text"> {{item.nodeName}}</span></div>
						</div>
						<div class="bottom">
							<div class="bottom-item-style">
								<img src="../../../assets/bddh.png" alt=""  @click="callPhone(item.phoneNo)">
								<span class="text">拨打电话</span>
							</div>
							<div class="bottom-item-style bottom-item-style1" v-if="item.statusCode==='未开工'">
								<img src="../../../assets/gcjd3.png" alt="">
								<span class="text">工程进度</span>
							</div>
							<div class="bottom-item-style" v-else @click="toProjectPlan(item)">
								<img src="../../../assets/gcjd.png" alt="">
								<span class="text">工程进度</span>
							</div>
							<div class="bottom-item-style" style="border: none;" @click="toPatrol(item)">
								<img src="../../../assets/xjjl.png" alt="">
								<span class="text">巡检记录</span>
							</div>
						</div>
					</div>
				</van-list>
			</pullRefresh>
		</div>
	</div>
</template>

<script>
	import api from '@/api/api.js'
	import filterModel from '@/components/filterModel2.vue'
	import {
		timeFormat
	} from '@/utils/timeCompute'
	export default {
		data() {
			return {
				searchValue: '',
				list: [],
				action: {},
				actions: [],
				filterTypeId: '',
				filterCondition: [{
						typeId: 'statusCode',
						dictValue: 'default',
						dictLabel: '施工状态',
						default: '施工状态',
						actions: []
					},
					{
						typeId: 'processCode',
						type: 'Cascader',
						selectTitle: '选择施工进度',
						dictValue: 'default',
						dictLabel: '施工进度',
						default: '施工进度',
						actions: [{
							dictValue: 'default',
							dictLabel: '所有进度'
						}, ]
					},
					{
						typeId: 'date-quantum',
						dictValue: 'default',
						dictLabel: '开工日期',
						default: '开工日期',
						actions: [{
							dictValue: 'default',
							dictLabel: '所有日期'
						}, {
							dictValue: '1',
							dictLabel: '自定义日期'
						}]
					},
				],
				isLoading: false,
				sheetShow: false,
				loading: false,
				finished: false,
				error: false, // 是否加载失败
				pageNum: 1, // 分页
				pageSize: 10, // 每页条数
				total: 0, // 数据总条数
			}
		},
		components: {
			filterModel
		},
        created(){
            this.onQueryProjectStatus()
        },
		methods: {
			//返回首页
			back(){
				this.$router.push({name:'indexName'})
			},
			PullRefresh() {
				this.onRefresh()
			},
            // 查询工程状态
            async onQueryProjectStatus(){
                const {data} = await api.queryProjectStatus({Q_dictId_EQ: "54286d9092716ec28bb614373d7fa717"})
                let statusData = data.map(item =>({ dictValue: item.code, dictLabel: item.name }))
                this.filterCondition[0].actions = [{dictValue: 'default', dictLabel: '所用状态'},...statusData]
            },
			//获取列表
			async getList(e) {
				let obj = {}
				if (e) {
					let d = []
					if(e[2].dictValue != "default"){
						d[0] = e[2].dictLabel[0]+' 00:00:00'
						d[1] = e[2].dictLabel[1]+' 23:59:59'
					}
					obj = {
						page: this.pageNum,
						limit: this.pageSize,
						time: e[2].dictValue == "default" ? '' :d.join('~'),
						statusCode: e[0].dictValue == "default" ? '' : e[0].dictValue,
						processCode: e[1].dictValue == "default" ? '' : e[1].dictValue,
						content: this.searchValue,
						timeType: "2"
					}
				} else {	
					obj = {
						page: this.pageNum,
						limit: this.pageSize,
						content: this.searchValue,
						time: '',
						processCode: '',
						statusCode: ''
					}
				}
				let res = await api.getPatrolList(obj)
				if (res.code == 200) {
					this.total = res.count;
					if (res.data.length === 0) { // 判断获取数据条数若等于0
						this.list = []; // 清空数组
						this.finished = true; // 停止加载
					} else {
						// 若数据条数不等于0
						this.list.push(...res.data) // 将数据放入list中
						this.loading = false; // 加载状态结束 
						// 如果list长度大于等于总数据条数,数据全部加载完成
						if (this.list.length >= res.count) {
							this.finished = true; // 结束加载状态
						}
					}
					this.isLoading = false
					if(this.$refs.pullRefresh){
						this.$refs.pullRefresh.refreshSuccess()
					}
				}
			},
			// 被 @load调用的方法
			onLoad(e) { // 若加载条到了底部
                e = e? e: this.filterCondition
				let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
					// this.getList(); // 调用上面方法,请求数据
					this.getList(e)
					this.pageNum++; // 分页数加一
					this.finished && clearTimeout(timer); //清除计时器
				}, 100);
			},
			// 加载失败调用方法
			onRefresh(e) {
				this.finished = false; // 清空列表数据
				this.loading = true; // 将 loading 设置为 true，表示处于加载状态
				this.pageNum = 1; // 分页数赋值为1
				this.list = []; // 清空数组
				this.onLoad(e); // 重新加载数据
			},
			toClientDetail(item) {
				sessionStorage.setItem('clientInfo',JSON.stringify(item))
				this.$router.push({
					name: "deepenClicntDetailName",
				})
			},
			//前往巡检记录
			toPatrol(item) {
				sessionStorage.setItem('clientInfo',JSON.stringify(item))
				sessionStorage.setItem('fromRouterNameDeepen', 'deepenConstructionName')
				this.$router.push({
					name: "deepenPatrolListlName",
					query: {
						isBack: true,
					}
				})
			},
			//前往工程进度
			toProjectPlan(item) {
				sessionStorage.setItem('clientInfo',JSON.stringify(item))
				sessionStorage.setItem('fromRouterNameDeepen', 'deepenConstructionName')
				this.$router.push({
					name: "deepenProjectPlanName",
					query: {
						isBack: true,
					}
				})
			},
			// 调用拨号功能
			callPhone (phoneNo) {
				if(!phoneNo) return
				window.location.href = 'tel://' + phoneNo
			},
			//施工进度状态列表
			async getProjectNodeList() {
				let obj = {	Q_isDel_EQ:'0' }
				let res = await api.getProjectCustomStageList(obj)
				if (res.code == 200) {
					let temp = []
					if(res.data?.length){
						res.data.forEach(e => {
							let children = null
							if(e.children?.length){
								children = e.children.map(e1 => ({ ...e1, dictValue: e1.stageCode, dictLabel: e1.stageName  }))
							}
							temp.push({ ...e, dictValue: e.stageCode, dictLabel: e.stageName, children })
						})
					}
					// res.data.forEach(e => {
					// 	temp.push({
					// 		dictValue: e.nodeCode,
					// 		dictLabel: e.nodeName
					// 	})
					// })
					this.filterCondition[1].actions.push(...temp)
				}
			}
		},
		mounted() {
			this.getProjectNodeList() //施工进度状态列表
		},
	}
</script>

<style scoped lang="scss">
	.construction-style {
		.search-style {
			height: 47px;
			background: #FFFFFF;
			display: flex;
			align-items: center;
			padding: 4px 10px;

			.choosetime-input {
				height: 40px;
				line-height: 40px;
				background: #F5F6F8;
				border-radius: 6px;
				padding: 0;
				padding-right: 5px;
				font-size: 13px;
			}

			.search-icon {
				width: 22px;
				height: 22px;
				margin: auto 0;
			}
		}

		.filter-style {
			height: 47px;
			background: #FFFFFF;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 1px;

			.filter-item {
				width: 124px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-right: 1px solid #E6E6E6;

				.item-style {
					display: inline-block;
					// width: 50px;
					margin-right: 4px;
				}

				.icon-style {
					display: inline-block;
					// height: 40px;
					// line-height: 40px;
				}
			}

			.already-filter {
				color: #4F7AFD;
			}

			.content {
				padding-bottom: 81px;

				.sheet-top {
					position: relative;
					height: 45px;
					line-height: 45px;
					background: #F5F6F8;
					font-size: 16px;
					// font-family: Source Han Sans SC;
					font-weight: 400;


					.sheet-cancel {
						position: absolute;
						left: 28px;
						color: #999999;
					}

					.sheet-sure {
						position: absolute;
						right: 28px;
						color: #4F7AFD;
					}
				}

				.sheet-content {
					background: #FFFFFF;

					.sheet-item {
						height: 45px;
						line-height: 45px;
						font-size: 16px;
						// font-family: Source Han Sans SC;
						font-weight: 400;
						color: #333333;
						text-align: left;
						margin: 0 15.5px;
						border-bottom: 1px solid #E6E6E6;
						display: flex;
						align-items: center;
						position: relative;

						.sheet-item-checked-icon {
							width: 22px;
							margin: auto 0;
							position: absolute;
							right: 11.5px;
						}
					}
				}
			}
		}

		.content-style {
			padding: 0 10px;
			height: calc(100vh - 196px);
			overflow: auto;

			.item-style {
				background: #FFFFFF;
				padding: 8px 0 0 0;
				margin-top: 10px;
				border-radius: 6px;

				.top {
					padding: 0 10px;
					padding-bottom: 7px;
					display: flex;
					text-align: left;
					position: relative;
					align-items: center;
					border-bottom: 1px solid #E6E6E6;

					.tx-img {
						width: 43px;
						height: 43px;
						margin-right: 11px;
					}

					.top-right {

						.name-style {
							height: 25px;
							line-height: 25px;
							font-size: 16px;
							// font-family: Source Han Sans SC;
							font-weight: 500;
							color: #333333;
							// margin-bottom: 10px;
						}

						.linkPhone-style {
							// height: 11px;
							// margin-top: 4px;
							font-size: 11px;
							font-family: Source Han Sans SC;
							font-weight: 400;
							color: #777777;
						}
					}

					.jt-img {
						width: 22px;
						height: 22px;
						position: absolute;
						right: 13px;
					}
				}

				.center {
					padding: 10px;
					border-bottom: 1px solid #E6E6E6;
					text-align: left;

					.center-top div {
						margin-right: 4px;
						padding: 1px 2px;
						// width: 126px;
						display: inline-block;
						height: 19px;
						line-height: 19px;
						background: #FFFFFF;
						border: 1px solid #4F7AFD;
						border-radius: 3px;
						font-size: 10px;
						// font-family: Source Han Sans SC;
						font-weight: 400;
						color: #4F7AFD;
						margin-bottom: 10px;
					}

					.title {
						margin-bottom: 7px;
						font-size: 11px;
						font-family: Source Han Sans SC;
						font-weight: 400;
						color: #777777;

						.text {
							color: #333333;
						}
					}
				}

				.bottom {
					display: flex;
					align-items: center;
					height: 40px;

					// padding: 14px 24px;
					.bottom-item-style {
						// height: 22px;
						display: flex;
						align-items: center;
						border-right: 1px solid #E6E6E6;
						width: 118px;
						justify-content: center;

						.text {
							// line-height: 22px;
							font-size: 13px;
							font-family: Source Han Sans SC;
							font-weight: 400;
							color: #4F7AFD;
						}

						img {
							width: 22px;
							height: 22px;
						}
					}
					.bottom-item-style1 {
						.text {
							color: #bbb;
						}
					}


				}
			}

			.vant-list-style{
				min-height: calc(100vh - 216px);
			}
		}
		::v-deep .van-tabs__nav {
			background-color: #fff !important;
		}
		
		::v-deep .van-tab {
			color: #323233 !important;
			background-color: #fff !important;
			border-radius: none !important;
			font-size: 14px !important;
		}
		
		::v-deep .van-tab--active {
			color: #323233 !important;
			background-color: #fff !important;
		}
		
		::v-deep .van-tabs__line {
			display: block !important;
		}
	}
</style>