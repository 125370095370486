<template>
    <div class="board-style">
        <!-- <headerNav title="数据看板"></headerNav> -->
        <div class="content-style">
            <!-- <van-dropdown-menu >
                <img class="positionIcon" src="../../assets/positionIcon.png" alt="">
                <van-dropdown-item v-model="value1" :options="option1" @change="marketChange" @closed="marketClosed" ref="market"/>
                <van-dropdown-item v-model="value1" :options="option2" @change="roleChange" />
            </van-dropdown-menu> -->
            <div class="screen" @click="showMenu = true">
                <img class="positionIcon" src="../../assets/menuIcon.png" alt="">
                <span class="text">{{ value1.text }}</span>
                <span>{{ value2.text }}</span>
            </div>
        </div>
        <div class="output" v-if="showOutput">
            <div class="outputTitle">
                <div @click="outputChange('1')" :class="active=='1'?'active':''"> 实际转单产值</div>
                <div @click="outputChange('2')" :class="active=='2'?'active':''"> 实际补齐产值</div>
            </div>
            <div class="outputContent">
                <div class="flex num1">
                    <div class="">
                        <div v-if="showMoney" class="money">****</div>
                        <div v-else class="money">{{ outputData.d.value.toFixed(2) }}</div>
                        <div>{{ outputData.d.name }}(元)</div>
                    </div>
                    <div v-if="outputData.d.value < outputData.a.value">
                      <div>完成率：<span class="completionRate">{{ outputData.a.percentage }}</span></div>
                    </div>
                    <div v-else-if="outputData.d.value < outputData.b.value">
                      <div>完成率：<span class="completionRate">{{ outputData.b.percentage }}</span></div>
                    </div>
                    <div v-else>
                      <div>完成率：<span class="completionRate">{{ outputData.c.percentage }}</span></div>
                    </div>
                </div>
                <div class="progress">
                    <div v-if="outputData.d.value < outputData.a.value">
                        <div class="progress1" :style="'width:'+(outputData.a.percentage)"></div>
                        <div class="flag1" :style="'left:100%'">
                            <img class="flag" src="../../assets/flag2.png" alt="">
                        </div>
                    </div>
                    <div v-else-if="outputData.d.value < outputData.b.value">
                        <div class="progress1" :style="'width:'+(outputData.b.percentage)"></div>
                        <div class="flag1" :style="'left:'+(outputData.a.value/outputData.b.value)*100+'%'"></div>
                        <div class="flag2" :style="'left:100%'">
                            <img class="flag" src="../../assets/flag3.png" alt="">
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="outputData.c.value <= 0">
                            <div class="progress1" style='width:0'></div>
                        </div>
                        <div v-else>
                            <div class="progress1" :style="'width:'+(outputData.c.percentage)"></div>
                            <div class="flag1" :style="'left:'+(outputData.a.value/outputData.c.value)*100+'%'"></div>
                            <div class="flag2" :style="'left:'+(outputData.b.value/outputData.c.value)*100+'%'"></div>
                            <div class="flag3" :style="'left:'+(outputData.c.value/outputData.c.value)*100+'%'">
                                <img class="flag" src="../../assets/flag1.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex num2">
                    <div class="flex">
                        <!-- <div v-if="showMoney" class="money">****</div>
                        <div v-else-if="!showMoney" class="money">1234</div> -->

                        <div class="content" v-if="outputData.d.value < outputData.a.value">
                            <div v-if="showMoney" class="money">****</div>
                            <div v-else-if="!showMoney" class="money">{{ outputData.a.value }}</div>
                            <div>{{outputData.a.name}}(元) <img class="flag" src="../../assets/flag2.png" alt=""></div>
                        </div>
                        <div class="content" v-else-if="outputData.d.value < outputData.b.value">
                            <div v-if="showMoney" class="money">****</div>
                            <div v-else-if="!showMoney" class="money">{{ outputData.b.value }}</div>
                            <div>{{outputData.b.name}}(元) <img class="flag" src="../../assets/flag3.png" alt=""></div>
                        </div>
                        <div class="content" v-else>
                            <div v-if="showMoney" class="money">****</div>
                            <div v-else-if="!showMoney" class="money">{{ outputData.c.value }}</div>
                            <div>{{outputData.c.name}}(元) <img class="flag" src="../../assets/flag1.png" alt=""></div>
                        </div>
                        <div class="tip" v-if="showSendBtn && outputData.c.value == 0 && outputType != '1' ">暂未设置，<span style="color:#4871C0;" @click="outputRemind">发送设置提醒 <van-icon name="arrow" /></span></div>
                        <div class="tip" v-else-if="showSendBtn && outputData.c.value == 0 && outputType == '1' ">设置提醒已发送,等待负责人设置</div>
                        <div class="tip" v-if="showSetBtn && outputData.c.value == 0">暂未设置，<span @click="showPopup" style="color:#4871C0;">去设置 <van-icon name="arrow" /></span></div>
                    </div>
                    <div class="eye" @click="money">
                        <img v-if="showMoney" src="../../assets/showIcon.png" alt="">
                        <img v-else src="../../assets/hideIcon.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="indicators" v-if="showIndicators">
            <div class="nav">
                <div class="title">数据指标</div>
                <van-dropdown-menu class="select" style="width: 75px;">
                    <van-dropdown-item v-model="value3" :options="option3" @change="dataIndicatorsChange"/>
                </van-dropdown-menu>
            </div>
            <div class="content">
                <div class="value" v-for="(item,index) in dataIndicators" :key="item.itemName" @click="handleClick(item)">
                    <div v-if="item.itemNo<5" >
                        <div class="num">{{ item.itemNum }}</div>
                        <div>{{ item.itemName }} <van-icon name="arrow" /></div>
                    </div>
                </div>
                <div class="value1" v-for="(item1,index1) in dataIndicators" :key="index1" >
                    <div class="specialVal" v-if="item1.itemNo == 5" @click="handleClick(item1)">
                        <div style="width: 50%;">
                            <div>转单量 <van-icon name="arrow" /></div>
                            <div class="num">{{ item1.itemNum }}</div>
                        </div>
                        <div class="line" />
                        <div style="width: 50%;">
                            <div>实际转单产值(万元)</div>
                            <div class="num num1">{{ ((item1.itemValue)/10000).toFixed(2) }}</div>
                        </div>
                    </div>
                    <div class="specialVal" v-if="item1.itemNo == 6" @click="handleClick(item1)">
                        <div style="width: 50%;">
                            <div>补齐量 <van-icon name="arrow" /></div>
                            <div class="num">{{ item1.itemNum }}</div>
                        </div>
                        <div class="line" />
                        <div style="width: 50%;">
                            <div>实际补齐产值(万元)</div>
                            <div class="num num1">{{ (item1.itemValue/10000).toFixed(2) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ranking" v-if="showRanking">
            <div class="nav">
                <div class="title">排行榜</div>
                <van-dropdown-menu class="select" style="width: 75px;">
                    <van-dropdown-item v-model="value4" :options="option4" @change="rankingChange"/>
                </van-dropdown-menu>
            </div>
            <div class="content">
                <van-tabs v-model="rankActive" color="#EBBA00" title-active-color="#333333" title-inactive-color="#999999">
                    <van-tab title="话务员">
                        <el-table :data="tableData1" style="width: 100%" v-if="tableData1.length>0" @sort-change="sortChange">
                            <el-table-column
                                type="index"
                                label="TOP"
                                align="center"
                                fixed='left'
                                width="60"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="telephonistName"
                                label="姓名"
                                width="100"
                                fixed>
                            </el-table-column>
                            <el-table-column
                                prop="clueValid"
                                label="有效数"
                                sortable
                                min-width="100">
                                <template slot-scope="scope">
                                    <span class="numColor1">{{ scope.row.clueValid }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="clueValidRate"
                                label="有效率"
                                sortable
                                min-width="100">
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.clueValidRate).toFixed(2) }}%</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="shopNum"
                                label="到店数"
                                sortable
                                min-width="100">
                                <template slot-scope="scope">
                                    <span class="numColor2">{{ scope.row.shopNum }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="shopRate"
                                label="到店率"
                                sortable
                                min-width="100">
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.shopRate).toFixed(2) }}%</span>
                                </template>
                            </el-table-column>
                            <el-table-column fixed='right' prop=" " label=" " width="10"></el-table-column>
                        </el-table>
                        <div class="noneData" v-else>
                            <img src="../../assets/none.png" alt="">
                            <div>暂无数据</div>
                        </div>
                        <div class="more" v-if="tableLength1 > 5" @click="rankDetail('0')">查看详情</div>
                    </van-tab>
                    <van-tab title="签单手">
                        <el-table :data="tableData2" style="width: 100%" v-if="tableData2.length>0" @sort-change="sortChange">
                            <el-table-column
                                type="index"
                                label="TOP"
                                align="center"
                                fixed='left'
                                width="60">
                            </el-table-column>
                            <el-table-column
                                prop="signName"
                                label="姓名"
                                width="100"
                                fixed>
                            </el-table-column>
                            <el-table-column
                                prop="orderReceivingNum"
                                label="接单数"
                                sortable
                                min-width="100">
                            </el-table-column>
                            <el-table-column
                                prop="validClientNum"
                                label="有效数"
                                sortable
                                min-width="100">
                                <template slot-scope="scope">
                                    <span class="numColor1">{{ scope.row.validClientNum }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="signNum"
                                label="签单数"
                                sortable
                                min-width="100">
                                <template slot-scope="scope">
                                    <span class="numColor2">{{ scope.row.signNum }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="signRate"
                                label="签单率"
                                sortable
                                min-width="100">
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.signRate).toFixed(2) }}%</span>
                                </template>
                            </el-table-column>
                            <el-table-column fixed='right' prop=" " label=" " width="10"></el-table-column>
                        </el-table> 
                        <div class="noneData" v-else>
                            <img src="../../assets/none.png" alt="">
                            <div>暂无数据</div>
                        </div>
                        <div class="more" v-if="tableLength2 > 5" @click="rankDetail('1')">查看详情</div>
                    </van-tab>
                    <van-tab title="设计师">
                        <el-table :data="tableData3" style="width: 100%" v-if="tableData3.length>0" @sort-change="sortChange">
                            <el-table-column
                                type="index"
                                label="TOP"
                                align="center"
                                fixed='left'
                                width="60">
                            </el-table-column>
                            <el-table-column
                                prop="transferName"
                                label="姓名"
                                width="100"
                                fixed>
                            </el-table-column>
                            <el-table-column
                                prop="transferSum"
                                label="转单数"
                                sortable
                                min-width="100">
                            </el-table-column>
                            <el-table-column
                                prop="thisMonthPro"
                                label="转单产值(元)"
                                sortable
                                align="right"
                                min-width="120">
                                <template slot-scope="scope">
                                    <span class="numColor3">{{ (scope.row.thisMonthPro).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="polishingNum"
                                label="补齐数"
                                sortable
                                min-width="100">
                            </el-table-column>
                            <el-table-column
                                prop="polishingValue"
                                label="补齐产值(元)"
                                sortable
                                align="right"
                                min-width="120">
                                <template slot-scope="scope">
                                    <span class="numColor3">{{ (scope.row.polishingValue).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column fixed='right' prop=" " label=" " width="10"></el-table-column>
                        </el-table> 
                        <div class="noneData" v-else>
                            <img src="../../assets/none.png" alt="">
                            <div>暂无数据</div>
                        </div>
                        <div class="more" v-if="tableLength3 > 5" @click="rankDetail('2')">查看详情</div>
                    </van-tab>
                    <van-tab title="督导">
                        <el-table :data="tableData4" style="width: 100%" v-if="tableData4.length>0" @sort-change="sortChange">
                            <el-table-column
                                type="index"
                                label="TOP"
                                align="center"
                                fixed='left'
                                width="60">
                            </el-table-column>
                            <el-table-column
                                prop="supervisorName"
                                label="姓名"
                                width="100"
                                fixed>
                            </el-table-column>
                            <el-table-column
                                prop="transferSum"
                                label="转单数"
                                sortable
                                min-width="100">
                            </el-table-column>
                            <el-table-column
                                prop="thisMonthPro"
                                label="转单产值(元)"
                                sortable
                                align="right"
                                width="150">
                                <template slot-scope="scope">
                                    <span class="numColor3">{{ (scope.row.thisMonthPro).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="polishingNum"
                                label="补齐数"
                                sortable
                                min-width="100">
                            </el-table-column>
                            <el-table-column
                                prop="polishingValue"
                                label="补齐产值(元)"
                                sortable
                                align="right"
                                width="150">
                                <template slot-scope="scope">
                                    <span class="numColor3">{{ (scope.row.polishingValue).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column fixed='right' prop=" " label=" " width="10"></el-table-column>
                        </el-table>
                        <div class="noneData" v-else>
                            <img src="../../assets/none.png" alt="">
                            <div>暂无数据</div>
                        </div>
                        <div class="more" v-if="tableLength4 > 5" @click="rankDetail('3')">查看详情</div>
                    </van-tab>
                </van-tabs>
            </div>
        </div>

        <van-popup v-model="showPop" position="bottom" :style="{ height: '65%' }">
            <div class="popTitle">新增年度目标设置</div>
            <div class="popContent">
                <van-form @submit="onSubmit">
                    <van-field
                        name="marketName"
                        :value="outputMarketValue.text"
                        label="市场"
                        placeholder="请选择"
                        :rules="[{ required: true, message: '请选择市场' }]"
                        @click="showPickerMarket = true"
                        />
                    <van-popup v-model="showPickerMarket" position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="columns"
                            @confirm="onOutputMarketValue"
                            @cancel="showPickerMarket = false"
                        />
                    </van-popup>
    
                    <van-field
                        name="configDate"
                        :value="outputAnnualValue"
                        label="年度"
                        placeholder="请选择"
                        :rules="[{ required: true, message: '请选择年度' }]"
                        @click="showPickerAnnual = true"
                        />
                    <van-popup v-model="showPickerAnnual" position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="columns1"
                            @confirm="onOutputAnnualValue"
                            @cancel="showPickerAnnual = false"
                        />
                    </van-popup>
                    <van-field
                        v-model="productionMiniValue"
                        type="digit"
                        name="productionMiniValue"
                        label="保底产值(万元)"
                        placeholder="请输入"
                        :rules="[{ required: true, message: '请填写保底产值' }]"
                    />
                    <van-field
                        v-model="productionJobValue"
                        type="digit"
                        name="productionJobValue"
                        label="任务产值(万元)"
                        placeholder="请输入"
                        :rules="[{ required: true, message: '请填写任务产值' }]"
                    />
                    <van-field
                        v-model="productionGoalValue"
                        type="digit"
                        name="productionGoalValue"
                        label="目标产值(万元)"
                        placeholder="请输入"
                        :rules="[{ required: true, message: '请填写目标产值' }]"
                    />
                    <div style="margin: 45px 0 20px 0">
                        <van-button round block type="info" native-type="submit">提交</van-button>
                    </div>
                </van-form>
            </div>
        </van-popup>

        <van-popup v-model="showMenu" round position="bottom" :style="{ height: '45%' }" @close="menuClose">
            <div class="popup">
                <div class="menu">
                    <div class="title">选择角色</div>
                    <div class="option">
                        <div v-for="(item,index) in option2" :class="item.flag?'active':''" @click="roleChange(item)">
                            {{ item.text }}
                        </div>
                    </div>
                </div>
                <div class="menu">
                    <div class="title">选择市场</div>
                    <div class="option">
                        <div v-for="(item,index) in option1" :class="item.flag?'active':''" @click="marketChange(item)">
                            {{ item.text }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="menuBtn">
                <div @click="showMenu = false">取消</div>
                <div @click="menuConfirm">确定</div>
            </div>
        </van-popup>

    </div>
</template>

<script>
	import api from '@/api/dataBoard.js'
    import moment from 'moment';
    import { Toast } from 'vant';
	import {
		timeFormat
	} from '@/utils/timeCompute'
	export default {
		data() {
			return {
                value1: { text: '全部市场', value: '99' }, //选择的市场
                value2: { text: '请选择角色', value: '' }, //选择的角色
                showMenu:false,
                stagingValue1: { text: '全部市场', value: '99' },
                stagingValue2: { text: '请选择角色', value: '' },
                option1: [{ text: '全部市场', value: '99', flag: true }],  //市场
                option2: [{ text: '请选择角色', value: '',flag: true }],  //角色
                // 模块显示
                showOutput:false,
                showIndicators:false,
                showRanking:false,

                // 产值
                active: 1,
                outputConfigDate:'', //产值时间
                outputType:'',
                outputData:{
                    a:{value1: 0, rate: 0, percentage: "0%", name: "保底", value: 0},
                    b:{value1: 0, rate: 0, percentage: "0%", name: "任务", value: 0},
                    c:{value1: 0, rate: 0, percentage: "0%", name: "目标", value: 0},
                    d:{value1: 0, rate: 0, percentage: "0%", name: "实际", value: 0}
                },
                showMoney:false,
                showPop: false,
                // 产值设置弹窗
                outputMarketValue: '',
                columns: [
                    { text: '济南市场', value: '370100' },
                    { text: '青岛市场', value: '370200' },
                    { text: '淄博市场', value: '370300' },
                    { text: '烟台市场', value: '370600' },
                    { text: '潍坊市场', value: '370700' },
                    { text: '威海市场', value: '371000' },
                    { text: '济宁市场', value: '370800' },
                    { text: '临沂市场', value: '371300' },
                ],
                showPickerMarket: false,
                outputAnnualValue:'',
                showPickerAnnual:false,
                columns1: [],


                productionMiniValue:'',
                productionJobValue:'',
                productionGoalValue:'',

                //数据指标 
                value3:'1',  
                showPopover:false,
                option3: [
                    { text: '昨日', value: '6' },
                    { text: '今日', value: '1' },
                    { text: '本周', value: '2' },
                    { text: '本月', value: '3' },
                    { text: '本年', value: '4' },
                ],
                reportData1:'',
                reportData2:'',
                oldReportData1:'',
                oldReportData2:'',
                dataIndicators:[],
                
                // 排行榜
                value4:'3',
                option4: [
                    { text: '今日', value: '1' },
                    { text: '本周', value: '2' },
                    { text: '本月', value: '3' },
                    { text: '本年', value: '4' },
                ],
                rankActive:0,
                reportDataRank:'',
                reportDataRank1:[],

                tableData1:[],
                tableData2:[],
                tableData3:[],
                tableData4:[],
                tableLength1:0,
                tableLength2:0,
                tableLength3:0,
                tableLength4:0,
                // 表格排序字段
                tableSort1:{},
                tableSort2:{},
                tableSort3:{},
                tableSort4:{},
                
                showSetBtn:false,
                showSendBtn:false,

                marketflag:false,
            }
		},
		components: {
		},
		created() {
		},
        mounted() {
            const date = new Date();
            for (let i = date.getFullYear() - 10; i <= date.getFullYear()+10; i++) {
                this.columns1.push(i);
            }
            this.getMarket()
		},
		methods: {
            getModule(){
                api.moduleApi().then((res)=>{
                    console.log(res)
                    res.data?.module.forEach((item)=>{
                        if(item.moduleCode == 'MODULE_763567' && item.status != '1'){
                            this.showIndicators = true
                        }
                        if(item.moduleCode == 'MODULE_555942' && item.status != '1'){
                            this.showRanking = true
                        }
                        if(item.moduleCode == 'MODULE_754560' && item.status != '1'){
                            this.showOutput = true
                        }
                    })
                    
                    if(res.data?.role.length > 1){
                        res.data?.role.forEach((item,index)=>{
                            item.flag = false
                            this.option2.push({text: item.moduleName, value: item.id, flag:item.flag })
                        })

                    }else{
                        this.option2 = []
                        res.data?.role.forEach((item,index)=>{
                            item.flag = false
                            if(index == 0){
                                item.flag = true
                            }
                            this.option2.push({text: item.moduleName, value: item.id,flag:item.flag})
                        })
                        this.value2 = this.option2[0]
                        this.stagingValue2 = this.value2
                        if(this.value2.value == 'f82711cf4f36029cd568f6f33e183d2c'){
                            this.showSetBtn = false
                            this.showSendBtn = true
                        }
                    }
                })
            },
            getMarket(){
                api.marketApi().then((res)=>{
                    if( res.code != 200 ){
                        console.log(res.code);
                        return false
                    }
                    if(res.data?.length > 1){
                        res.data?.forEach((item)=>{
                            item.flag = false
                            this.option1.push(item) 
                        })
                    }else{
                        res.data?.forEach((item,index)=>{
                            item.flag = false
                            if(index == 0){
                                item.flag = true
                            }
                        })
                        this.option1 = res.data
                        this.value1 = res.data[0]
                        this.stagingValue1 = res.data[0]
                    }
                    
                    this.getBtnPower()
                    this.getModule()
                    this.outputConfigDate = moment().format('YYYY')
                    this.getOutput(this.active)

                    this.reportData1 = moment().format('YYYY-MM-DD');
                    this.reportData2 = moment().format('YYYY-MM-DD');
                    this.oldReportData1 = moment(moment().year(moment().year() - 1)).format('YYYY-MM-DD');
                    this.oldReportData2 = moment(moment().year(moment().year() - 1)).format('YYYY-MM-DD');
                    this.getDataIndicators()

                    this.reportDataRank = moment().format('YYYY-MM')
                    this.getRank()
                })
            },
            marketChange(e){
                this.option1.forEach((item)=>{
                    item.flag = false
                    if(item.value == e.value){
                        item.flag = true
                    }
                })
                this.stagingValue1 = e
            },
            roleChange(e){
                this.option2.forEach((item)=>{
                    item.flag = false
                    if(item.value == e.value){
                        item.flag = true
                    }
                })
                this.stagingValue2 = e
            },
            menuClose(){
                this.option1.forEach((item)=>{
                    item.flag = false
                    if(item.value == this.value1.value){
                        item.flag = true
                    }
                })
                this.option2.forEach((item)=>{
                    item.flag = false
                    if(item.value == this.value2.value){
                        item.flag = true
                    }
                })
            },
            menuConfirm(){
                this.showMenu = false
                this.value1 = this.stagingValue1
                this.value2 = this.stagingValue2
                
                this.showIndicators = false
                this.showRanking = false
                this.showOutput = false

                this.getOutput(this.active)
                this.getDataIndicators()
                this.getRank()

                if(this.value2.value == ''){
                    api.moduleApi().then((res)=>{
                        res.data?.module.forEach((item)=>{
                            if(item.moduleCode == 'MODULE_763567' && item.status != '1'){
                                this.showIndicators = true
                            }
                            if(item.moduleCode == 'MODULE_555942' && item.status != '1'){
                                this.showRanking = true
                            }
                            if(item.moduleCode == 'MODULE_754560' && item.status != '1'){
                                this.showOutput = true
                            }
                        })
                        this.getBtnPower()
                    })
                }else{
                    this.showSendBtn = false
                    if(this.value2.value == 'f82711cf4f36029cd568f6f33e183d2c'){
                        this.showSendBtn = true
                    }

                    api.roleModuleApi({'Q_parentId_EQ':this.value2.value}).then((res)=>{
                        res.data.forEach((item)=>{
                            if(item.moduleCode == 'MODULE_763567' && item.status != '1'){
                                this.showIndicators = true
                            }
                            if(item.moduleCode == 'MODULE_555942' && item.status != '1'){
                                this.showRanking = true
                            }
                            if(item.moduleCode == 'MODULE_754560' && item.status != '1'){
                                this.showOutput = true
                            }
                        })
                    })
                }
            },

            // 产值
            getOutput(type){
                let params = {
                    marketCode: this.value1.value,
                    Q_configDate_EQ:this.outputConfigDate,
                    Q_productionType_EQ:type
                }
                api.outputApi(params).then((res)=>{
                    this.outputData = res.data.data
                    this.outputType = res.data.type
                })
            },
            outputChange(e){
                this.active = e
                this.getOutput(this.active)
            },
            money(){
                this.showMoney = !this.showMoney
            },
            showPopup() {
                this.showPop = true;
            },
            onConfirm(value) {
                this.value = value;
                this.showPicker = false;
            },
            onOutputMarketValue(e){
                console.log(e);
                this.outputMarketValue = e
                this.showPickerMarket = false
            },
            onOutputAnnualValue(e){
                console.log(e);
                this.outputAnnualValue = e
                this.showPickerAnnual = false
            },
            onSubmit(e){
                let params = {
                    ...e,
                    marketCode:this.outputMarketValue.value,
                    productionType:this.active,
                    configType:'1',
                }
                console.log(params);
                api.outputSetAddApi(params).then((res)=>{
                    this.showPop = false;

                    this.outputMarketValue = ''
                    this.outputAnnualValue = ''
                    this.productionMiniValue = ''
                    this.productionJobValue = ''
                    this.productionGoalValue = ''

                    Toast.success('设置成功');

                    this.getOutput(this.active)

                })
            },

            // 数据指标
            getDataIndicators() {
                let params = {
                    type: this.value3,
                    reportData1: this.reportData1,
                    reportData2: this.reportData2,
                    oldReportData1: this.oldReportData1,
                    oldReportData2: this.oldReportData2,
                    marketCode: this.value1.value,
                }
                api.cluesNumApi(params).then((res)=>{
                    console.log(res);
                    this.dataIndicators = res.data || []
                })
            },
            dataIndicatorsChange(e){
                console.log(this.value3);
                if(this.value3 == 1 ){
                    this.reportData1 = moment().format('YYYY-MM-DD');
                    this.reportData2 = moment().format('YYYY-MM-DD');
                    this.oldReportData1 = moment(moment().year(moment().year() - 1)).format('YYYY-MM-DD');
                    this.oldReportData2 = moment(moment().year(moment().year() - 1)).format('YYYY-MM-DD');
                }else if(this.value3 == 2){
                    this.reportData1 = moment().startOf('week').add(1,'days').format('YYYY-MM-DD') //本周一
                    this.reportData2 = moment().endOf('week').add(1,'days').format('YYYY-MM-DD') //本周日
                    this.oldReportData1 = moment(this.reportData1).subtract(1,'years').format('YYYY-MM-DD');
                    this.oldReportData2 = moment(this.reportData2).subtract(1,'years').format('YYYY-MM-DD');
                }else if(this.value3 == 3){
                    this.reportData1 = moment().startOf('month').format('YYYY-MM')
                    this.reportData2 = moment().endOf('month').format('YYYY-MM')
                    this.oldReportData1 = moment(this.reportData1).subtract(1,'years').format('YYYY-MM');
                    this.oldReportData2 = moment(this.reportData2).subtract(1,'years').format('YYYY-MM');
                }else if(this.value3 == 4){
                    this.reportData1 = moment().format('YYYY')
                    this.reportData2 = moment().format('YYYY')
                    this.oldReportData1 = moment(moment().year(moment().year() - 1)).format('YYYY');
                    this.oldReportData2 = moment(moment().year(moment().year() - 1)).format('YYYY');
                }else if(this.value3 == 6){
                    this.reportData1 = moment().subtract(1,'day').format('YYYY-MM-DD');
                    this.reportData2 =  moment().subtract(1,'day').format('YYYY-MM-DD');
                    this.oldReportData1 = moment(this.reportData1).subtract(1,'years').format('YYYY-MM-DD');
                    this.oldReportData2 = moment(this.reportData2).subtract(1,'years').format('YYYY-MM-DD');
                }
                this.getDataIndicators()
            },
            // 数据指标路由跳转
            handleClick(e){
                console.log(e);
                if(e.itemNo=='5'|| e.itemNo=='6'){
                    this.$router.push({
                        name: 'indicators2',
                        query: {
                            name:e.itemName,
                            type:e.itemNo,
                            marketCode: this.value1.value,
                            reportData: this.value3
                        }
                    })
                }else{
                    this.$router.push({
                        name: 'indicators1',
                        query: {
                            name:e.itemName,
                            type:e.itemNo,
                            marketCode: this.value1.value,
                            reportData: this.value3
                        }
                    })
                }
            },

            // 排行榜
            getRank(){
                let params = {
                    "type": this.value4, 
                    "reportData": this.reportDataRank,
                    'startDate':this.reportDataRank1[0] || '',
                    'endDate':this.reportDataRank1[1] || '',
                    "marketCode": this.value1.value
                }
                this.getTraffic(params)
                this.getSigning(params)
                this.getTransferOrder(params)
                this.getSupervision(params)
            },
            rankingChange(){
                this.reportDataRank = ''
                this.reportDataRank1 = []
                if(this.value4 == 1){
                    this.reportDataRank1 = [moment().format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')]
                }else if(this.value4 == 2){
                    this.reportDataRank = moment().format('YYYY-MM-WW')
                }else if(this.value4 == 3){
                    this.reportDataRank = moment().format('YYYY-MM')
                }else if(this.value4 == 4){
                    this.reportDataRank = moment().format('YYYY')
                }
                this.getRank()
            },
            tableRowClassName({row,rowIndex}){
                console.log(row,rowIndex);
                if(rowIndex>4){
                    return 'hidden_box'
                }   
                return ''
            },
            getTraffic(params) {
                api.trafficApi(params).then((res)=>{
                    console.log(res);
                    let data = [...res.data]
                    this.tableLength1 = res.data.length || 0
                    this.tableData1 = data.slice(0,5)||[];
                    console.log(this.tableLength1);
                })
            },
            getSigning(params) {
                api.signingApi(params).then((res)=>{
                    console.log(res);
                    let data = [...res.data]
                    this.tableLength2 = res.data.length || 0
                    this.tableData2 = data.slice(0,5)||[];
                })
            },
            getTransferOrder(params) {
                api.transferOrderApi(params).then((res)=>{
                    console.log(res);
                    let data = [...res.data]
                    this.tableLength3 = res.data.length || 0
                    this.tableData3 = data.slice(0,5)||[];
                })
            },
            getSupervision(params) {
                api.supervisionApi(params).then((res)=>{
                    console.log(res.data);
                    let data = [...res.data]
                    this.tableLength4 = res.data.length || 0
                    this.tableData4 = data.slice(0,5)||[];
                })
            },
            sortChange(column){
                console.log(this.rankActive,column);
                if(this.rankActive == '0'){
                    this.tableSort1 = column
                }else if(this.rankActive == '1'){
                    this.tableSort2 = column
                }else if(this.rankActive == '2'){
                    this.tableSort3 = column
                }else if(this.rankActive == '3'){
                    this.tableSort4 = column
                }
            },
            rankDetail(type){
                let sortArr = {}
                // 排序字段
                if(type == '0'){
                    sortArr = this.tableSort1
                }else if(type == '1'){
                    sortArr = this.tableSort2
                }else if(type == '2'){
                    sortArr = this.tableSort3
                }else if(type == '3'){
                    sortArr = this.tableSort4
                }
                let params = {
                    navType:type,
                    dataType:this.value4,
                    marketCode: this.value1,
                    prop: sortArr.prop||"",
                    order: sortArr.order||""
                }
                console.log(params);
                this.$router.push({
                    name: 'rankDetails',
                    query: params
                })
            },

            getBtnPower(){
                let params = sessionStorage.getItem('USERINFO')
                let btn = JSON.parse(params);
                
                if(btn.btn.indexOf('directorKanban:transferOrderSetUp') != -1){
                    this.showSetBtn = true
                }else{
                    this.showSetBtn = false
                }
                if(btn.btn.indexOf('directorKanban:transferOrderSend') != -1){
                    this.showSendBtn = true
                }else{
                    this.showSendBtn = false
                }
                console.log(this.showSetBtn,this.showSendBtn);
            },
            outputRemind(){
                api.outputRemindApi({marketCode:this.value1.value,type:this.active}).then((res)=>{
                    Toast.success('已发送产值设置消息提醒');
                    this.getOutput(this.active)
                })
            }
        },
		
	}
</script>

<style scoped lang="scss">
	.board-style {
		.content-style {
			padding: 0 10px;
            background: #3975C6;
			overflow: auto;
            .select{
                position: relative;
            }
            .screen{
                width: auto;
                height: 50px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                text-align: left;
                line-height: 50px;
            }
            .positionIcon{
                width: 8px;
                height: 6px;
                vertical-align:6%;
            }
            .text{
                padding-right: 10px;
                margin:0 10px;
                border-right:1px #fff solid;
            }
		}
        .output{
            height: 100%;
            background: #F9F9F9;
            box-shadow: 0px 9px 7px 0px rgba(0,0,0,0.06);
            border-radius: 0 0 10px 10px; 
            margin-bottom: 15px;
            padding-top: 10px;
            .outputTitle{
                display: flex;
                div{
                    width: 50%;
                    line-height: 43px;
                    font-size: 14px;
                    font-family: PingFang-SC-Medium, PingFang-SC;
                    font-weight: 500;
                    color: #333333;
                    border-radius: 16px 16px 0px 0px;
                }
                .active{
                    background: #ffffff;
                    color: #3A72C6;
                    font-size: 15px;
                }
            }
            .outputContent{
                height: calc(100% - 73px);
                background: #ffffff;
                border-radius:0px 0px 16px 16px ;
                padding: 15px 20px;
                font-size: 14px;
                font-family: PingFang-SC-Medium, PingFang-SC;
                font-weight: 500;
                text-align: left;
                color: #8B959F;
                .num1{
                    justify-content: space-between;
                    margin-bottom: 15px;
                }
                .num2{
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 15px;
                    position: relative;
                    
                    .content{
                        min-width: 60px;
                        .flag{
                            width: 12px;
                            height: 14px;
                            vertical-align: middle;
                        }
                    }
                    .tip{
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        margin-left: -80px;
                        width: 200px;
                        background: #fff;
                    }
                }
                .eye{
                    width: 20px;
                    min-width: 20px;
                    height: 17px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .flag{
                    width: 14px;
                    height: 17px;
                    vertical-align: middle;
                }
                .money{
                    font-size: 20px;
                    font-family: bahnschrift;
                    font-weight: 800;
                    color: #181A1C;
                    text-align: left;
                    margin-bottom: 5px;
                }
                .progress{
                    width: 100%;
                    height: 18px;
                    background: #F0F0F0;
                    position: relative;
                    .progress1{
                        width: 80%;
                        height: 18px;
                        background: linear-gradient(270deg, #F7B500 0%, #FCDB00 100%);
                        box-shadow: 5px 6px 17px 0px rgba(247,181,0,0.2);
                        border-radius: 0px 100px 100px 0px;
                    }
                    .flag1{
                        position: absolute;
                        top: 0;
                        left: -100%;
                        width: 0;
                        height: 100%;
                        border-left: 1px #fff dashed;
                        img{
                            position: absolute;
                            top: -18px;
                            left: 0;
                        }
                    }
                    .flag2{
                        position: absolute;
                        top: 0;
                        left: -100%;
                        width: 0;
                        height: 100%;
                        border-left: 1px #36BB65 dashed;
                        img{
                            position: absolute;
                            top: -18px;
                            left: 0;
                        }
                    }
                    .flag3{
                        position: absolute;
                        top: 0;
                        left: -100%;
                        width: 0;
                        height: 100%;
                        border-left: 1px #FA6400 dashed;
                        img{
                            position: absolute;
                            top: -18px;
                            left: 0;
                        }
                    }
                }
            }
        }
        .indicators{
            // height: 300px;
            .nav{
                height: 35px;
                display: flex;
                justify-content: space-between;
                .title{
                    width: 100px;
                    line-height: 30px;
                    background: url('../../assets/indicatorsTBg.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }
                .select {
                    padding-right: 10px;
                }
            }
            .content{
                font-size: 12px;
                font-family: PingFang-SC-Medium, PingFang-SC;
                font-weight: 500;
                color: #999999;
                padding: 15px 15px 7px;
                display: flex;
                flex-wrap: wrap;
                .value{
                    text-align: left;
                    width: 25%;
                    margin-bottom: 10px;
                }
                .value1{
                    text-align: center;
                    width: 100%;
                    
                    .specialVal{
                        height: 80px;
                        background: #F5F6F8;
                        border-radius: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 8px;
                        .line{
                            width: 1px;
                            height: 53px;
                            background: #EEEEEE;
                        }
                        .num{
                            margin-top: 14px;
                            margin-bottom: 0;
                        }
                        .num1{
                            color: #FA6400;
                        }
                    }
                }
                .num{
                    font-size: 20px;
                    font-family: bahnschrift;
                    color: #181A1C;
                    margin-bottom: 10px;
                    // line-height: 28px;
                }
            }
        }

        .ranking{
            // height: 300px;
            .nav{
                height: 35px;
                display: flex;
                justify-content: space-between;
                padding-bottom: 10px;
                .title{
                    width: 100px;
                    line-height: 30px;
                    background: url('../../assets/rankIngTBg.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }
                .select {
                    padding-right: 10px;
                }
            }
            
            .more{
                width: 100%;
                height: 45px;
                line-height: 45px;
                background: #efefef;
                text-align: center;
                font-size: 12px;
            }
        }

        .popTitle{
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 25px;
            letter-spacing: 2px;
            padding: 25px;
            text-align: center;
        }
        .popContent{
            width: 80%;
            margin: 0 auto;
        }
	}

    .flex{
        display: flex;
    }

    .numColor1{
        color: #438F1D;
    }
    .numColor2{
        color: #4871C0;
    }
    
    .numColor3{
        color: #FA6400;
    }
    
    
    .noneData{
        width: 100%;
        height: 280px;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
        img{
            width: 200px;
            height: 140px;
        }
        div{
            width: 100%;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            margin-top: 5px;
        }
    }

    .popup{
        padding: 15px 15px 0px 15px;
        position: relative;
    }
    .menu{
        .title{
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            text-align: left;
            margin-bottom: 15px;
        }
        .option{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 7px;
            div{
                width: 23.5%;
                line-height: 36px;
                background: #F5F5F5;
                border-radius: 4px;
                margin-right: 1.5%;
                margin-bottom: 8px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }
            .active{
                background: #E7F1FF;
                color: #1677FF;
            }
        }
    }

    .menuBtn{
        position: absolute;
        left: 0;
        bottom: 2px;
        width: 100%;
        height: 60px;
        display: flex;

        div{
            width: 50%;
            height: 100%;
            line-height: 60px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
        div:nth-child(1){
            border: 1px solid #EEEEEE;
        }
        div:nth-child(2){
            background: #3975C6;
            border: 1px solid #3975C6;
            color: #fff;
        }
    }
    

    ::v-deep{
        .content-style .van-dropdown-menu__bar{
            // background: #4871C0;
        }
        .van-dropdown-menu__bar{
            justify-content: space-between;
            background: transparent;
            padding: 0 10px;
        }
        .content-style .van-dropdown-menu__title{
            color: #FFFFFF;
        }
        .van-dropdown-menu__title{
            color: #000000;
        }
        .van-dropdown-menu__item{
            flex: none;
        }
        .van-dropdown-menu__item:nth-child(1){
        }
        .van-dropdown-menu__item:nth-child(2){
            // padding-right: 10px;
        }
        .van-dropdown-item__option--active,.van-dropdown-item__option--active .van-dropdown-item__icon{
            color: #00aeff;
        }

        .indicators .van-dropdown-menu__bar,.ranking .van-dropdown-menu__bar{
            height: 100%;
            border-radius: 10px;
            padding: 0;
            display: flex;
            justify-content: center;
        }

        .popContent .van-cell{
            margin-bottom: 20px;
        }

        .van-field__label{
            text-align: right;
            width: 100px;
        }

        .el-table .hidden_box{
            display: none;
        }
        .el-table--scrollable-x .el-table__body-wrapper{
            height: 201px !important;
        }
        .el-table .el-table__body-wrapper .el-table__cell{
            font-size: 15px;
        }
        .el-table .el-table__fixed .el-table__fixed-body-wrapper .el-table__cell{
            font-size: 15px;
        }

        .el-table__fixed-right{
            height: 55px !important;
        }
        .el-table__fixed-right .el-table__cell{
            background: linear-gradient(180deg, #F5F5F5 0%, #FFFFFF 100%) !important;
        }
        .el-table .cell{
            white-space: nowrap;
        }
        .el-table--scrollable-x .el-table__body-wrapper{
            height: 201px !important;
        }
    }
</style>
