<template>
  <div class="main_box">
    <headerNav title="消息详情" @leftClick="back"></headerNav>
    <div class="height_style1">
      <div class="info_main">
        <div class="info_nav">
          <div class="info_leftS">
            <div class="info_leftS_tit1">{{
              `关于客户[${customerDetails.consumerName}]预算单变更` }}</div>
            <div class="info_leftS_tit2">
              <div class="info_leftS_tit2L">合同号</div>
              <div class="info_leftS_tit2R">{{ customerDetails.contractNo }}</div>
            </div>
          </div>
          <div class="info_right">
            <img v-if="customerDetails.budgetPassThrough" src="../../assets/comfime.svg" alt="" class="img">
            <img v-if="!customerDetails.budgetPassThrough" src="../../assets/comfimeNo.svg" alt="" class="img">
          </div>
        </div>
        <div class="info_content">
          <div class="cont_main" v-if="isSub">
            <div class="cont_flex">
              <div class="flex_a">客户姓名:</div>
              <div class="flex_b">{{ customerDetails.consumerName }}</div>
            </div>
            <div class="cont_flex">
              <div class="flex_a">装修面积:</div>
              <div class="flex_b">{{ customerDetails.areaQty }}</div>
            </div>
            <div class="cont_flex">
              <div class="flex_a">区域:</div>
              <div class="flex_b">{{ customerDetails.provinceCityRegion }}</div>
            </div>
            <div class="cont_flex">
              <div class="flex_a">详细地址:</div>
              <div class="flex_b">{{ customerDetails.detailAddress }}</div>
            </div>
            <div class="cont_flex">
              <div class="flex_a">订单进度:</div>
              <div class="flex_b">{{ customerDetails.orderSchedule }}</div>
            </div>
            <div class="cont_flex">
              <div class="flex_a">已交金额:</div>
              <div class="flex_b">{{ customerDetails.collected }}</div>
            </div>
          </div>
          <div class="content_bottom" @click="handleChangeSub">
            <div class="flex">
              {{ isSub ? '收起更多' : '查看更多' }}
              <img :class="isSub ? '' : 'jiantou'" :src="require('@/assets/sqgdpng.png')" alt=""
                style="width:12px;height:12px;margin-left:4px">
            </div>
          </div>
        </div>
      </div>
      <!-- 中间 -->
    <div style="height:16px;width:100%;background:#F5F5F5"></div>
    <!-- 底部 -->
    <div class="bottom_img">
      <img src="../../assets/back-bottom-change.png" alt="" class="img">
      <div>
        <p class="one">请及时变更收款计划</p>
        <p>详情内容请到web端查看</p>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview, Dialog, Toast } from 'vant'
import api from '@/api/api.js'

export default {
  components: {
  },
  data() {
    return {
      id: '',
      logId: '',
      messageId: '',
      orderInfoId:'',
      orderNoticeType: '',
      isSub: true,
      dataTable: [],
      // 详情传值
      customerDetails: {},
      // 消息内容
      msgConent: {},
    }
  },
  props: {

  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    // 切换收起展开
    handleChangeSub() {
      this.isSub = !this.isSub
    },
    // 查询详情
    async handleDetails() {
      const data = await api.getBudgetInfo(this.orderInfoId)
      this.customerDetails = data.data
    },
  },
  mounted() {

  },
  created() {
    this.id = this.$route.params.id
    this.logId = this.$route.params.logId
    this.messageId = this.$route.params.messageId
    this.orderInfoId = this.$route.params.orderInfoId
    this.handleDetails()
  }
}
</script>

<style lang="scss" scoped>
.main_box {
  background: #F5F5F5;

  .color_blue {
    color: #3975C6;
  }
  .bottom_img{
    width: 100%;
    text-align: center;
    margin-top: 72px;
    img{
      width: 220px;
      height: 124px;
    }
    p{
      font-family: PingFang SC, PingFang SC;
      font-size: 16px;
      color: #333333;
      margin: 0px;

    }
    .one{
      margin: 21px 0 5px;
      font-weight: 500;
    }

  }
  .color_red {
    color: #EB5757;
  }

  .info_main {
    text-align: left;
    background: #3975C6;
    color: #fff;
    padding: 12px;
    font-family: PingFang SC-Heavy, PingFang SC;

    .info_nav {
      display: flex;
      align-items: center;

      .info_leftS {
        flex: 1;

        .info_leftS_tit1 {
          font-size: 18px;
          line-height: 21px;
          font-weight: bold;
        }

        .info_leftS_tit2 {
          margin-top: 10px;
          height: 22px;
          display: flex;
          align-items: center;

          .info_leftS_tit2L {
            width: 41px;
            height: 17px;
            line-height: 17px;
            font-size: 11px;
            color: #3975C6;
            background: #FFFFFF;
            border-radius: 1px;
            text-align: center;
          }

          .info_leftS_tit2R {
            margin-left: 8px;
            height: 21px;
            font-size: 15px;
          }
        }
      }

      .info_right {
        margin-left: 10px;
        width: 48px;
        height: 48px;
      }
    }

    .info_content {
      margin-top: 16px;

      .cont_main {
        border-radius: 4px;
        background: #2569C3;
        padding: 12px 0 8px;

        .cont_flex {
          display: flex;
          justify-content: space-between;
          color: #FFFFFF;
          padding: 8px;
          font-family: PingFang SC-Regular, PingFang SC;

          .flex_a {
            width: 80px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 16px;
            text-align: left;
          }

          .flex_b {
            max-width: calc(100% - 80px);
            font-size: 16px;
          }
        }

        .cont_flex_img {
          display: flex;
          justify-content: space-between;

          .flex_text {
            white-space: nowrap;
          }

          .flex_img {
            display: flex;
            flex-wrap: wrap;
            width: 232px;

            .img {
              // display: inline-block;
              margin: 0px 6px 6px 6px;
            }
          }
        }
      }

      .content_bottom {
        width: 100%;
        color: #ECF4FF;
        font-size: 12px;
        margin: 12px 0px 0px 0px;

        .flex {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFang SC-Regular, PingFang SC;
        }

        .jiantou {
          transform: rotate(180deg);
          transition: all 0.3s;
        }
      }
    }

  }

  .height_style1 {
    min-height: calc(100vh - 51px);
    overflow: auto;
    margin-top: -1px;
    background: #fff;
  }
}
</style>