<template>
    <div>
        <van-sticky class="topMenu">
            <headerNav leftNav=" " class="nav_b" @leftClick="back" :title="this.title" :rightIcon="'screenIcon'" @rightClick="rightClick"></headerNav>
        </van-sticky>
        <div class="trend" v-if="this.type == '1'||this.type == '2'">
            <div class="title">趋势</div>
            <div class="content">
                <div class="trendEchart"></div>
            </div>
        </div>
        <div class="channel" v-if="this.type == '1'||this.type == '2'">
            <div class="title">渠道占比</div>
            <div class="content">
                <div class="channelEchart" style="width: 100%;height: 100%;"></div>
            </div>
        </div>
        <div class="trend" v-if="this.type == '3'">
            <div class="title">趋势</div>
            <div class="content">
                <div class="toStoreEchart" style="width: 100%;height: 100%;"></div>
            </div>
        </div>
        <div class="trend" v-if="this.type == '4'">
            <div class="title">趋势</div>
            <div class="content">
                <div class="signEchart" style="width: 100%;height: 100%;"></div>
            </div>
        </div>
        <div class="table">
            <div class="title">数据表</div>
            <div class="content">
                <div v-if="this.type == '1'||this.type == '2'">
                    <el-table :data="cluesTable" style="width: 100%"  v-if="cluesTable.length>0" v-sticky="{ top:stickyOffset, parent:'#table_box' }">
                        <el-table-column
                            prop="reportData"
                            label="时间"
                            min-width="105">
                        </el-table-column>
                        <el-table-column
                            prop="clueAllocation"
                            label="进线量"
                            sortable
                            min-width="100">
                            <template slot-scope="scope">
                                <span class="numColor1">{{ scope.row.clueAllocation }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="clueValid"
                            label="当日有效"
                            sortable
                            min-width="120">
                        </el-table-column>
                        <el-table-column
                            prop="clueValidSum"
                            label="总有效"
                            sortable
                            min-width="100">
                            <template slot-scope="scope">
                                <span class="numColor2">{{ scope.row.clueValidSum }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="changeValidTotal"
                            label="转有效"
                            sortable
                            min-width="100">
                        </el-table-column>
                        <el-table-column
                            prop="clueValidRate"
                            label="日有效率"
                            sortable
                            min-width="120">
                            <template slot-scope="scope">
                                <span>{{ scope.row.clueValidRate?parseFloat(scope.row.clueValidRate).toFixed(2)+'%':'' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column fixed='right' prop=" " label=" " width="10"></el-table-column>
                    </el-table>
                    <div class="noneData" v-else>
                        <img src="../../assets/none.png" alt="">
                        <div>暂无数据</div>
                    </div>
                </div>
                <div v-if="this.type == '3'">
                    <el-table :data="toStoreTable" style="width: 100%"  v-if="toStoreTable.length>0" v-sticky="{ top:stickyOffset, parent:'#table_box' }">
                        <el-table-column
                            prop="reportData"
                            label="时间"
                            min-width="105">
                        </el-table-column>
                        <el-table-column
                            prop="actualShoupNum"
                            label="到店量"
                            sortable
                            min-width="100">
                        </el-table-column>
                        <el-table-column
                            prop="validShoupNum"
                            label="有效到店"
                            sortable
                            min-width="120">
                        </el-table-column>
                        <el-table-column
                            prop="inviteShoupNum"
                            label="邀约到店"
                            sortable
                            min-width="120">
                        </el-table-column>
                        <el-table-column
                            prop="temporaryCallerNum"
                            label="临时到店"
                            sortable
                            min-width="120">
                        </el-table-column>
                        <el-table-column
                            prop="turnIntroduce"
                            label="转介绍"
                            sortable
                            min-width="100">
                        </el-table-column>
                        <el-table-column fixed='right' prop=" " label=" " width="10"></el-table-column>
                    </el-table>
                    <div class="noneData" v-else>
                        <img src="../../assets/none.png" alt="">
                        <div>暂无数据</div>
                    </div>
                </div>
                <div v-if="this.type == '4'">
                    <el-table :data="signTable" style="width: 100%"  v-if="signTable.length>0" v-sticky="{ top:stickyOffset, parent:'#table_box' }">
                        <el-table-column
                            prop="signName"
                            label="签单手"
                            min-width="100">
                        </el-table-column>
                        <el-table-column
                            prop="orderReceivingNum"
                            label="接单量"
                            sortable
                            min-width="100">
                        </el-table-column>
                        <el-table-column
                            prop="signNum"
                            label="签单量"
                            sortable
                            min-width="100">
                        </el-table-column>
                        <el-table-column
                            prop="signRate"
                            label="签单率"
                            sortable
                            min-width="100">
                            <template slot-scope="scope">
                                <span>{{ scope.row.signRate?parseFloat(scope.row.signRate).toFixed(2)+'%':'' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="turnIntroduce"
                            label="转介绍"
                            sortable
                            min-width="100">
                        </el-table-column>
                        <el-table-column fixed='right' prop=" " label=" " width="10"></el-table-column>
                    </el-table>
                    <div class="noneData" v-else>
                        <img src="../../assets/none.png" alt="">
                        <div>暂无数据</div>
                    </div>
                </div>

            </div>
        </div>

        <!-- 筛选弹窗 -->
        <van-popup
            v-model="popupShow"
            class="my-popup"
            overlay-class="my-popup-cover"
            position="top"
        >
            <van-cell title="时间段：" :value="date" @click="show = true" />
            <van-calendar allow-same-day v-model="show" type="range" @confirm="onConfirm" color="#4871C0" :min-date="minDate" :max-date="maxDate"/>
            <div class="flex" style="padding: 10px;justify-content: center;">
                <div class="btn" @click="onResetting">
                    重置
                </div>
                <div class="btn btn1" @click="onAffirm">
                    确认
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
	import api from '@/api/dataBoard.js'
    import moment from 'moment';
    import { Toast } from 'vant';
    import * as echarts from 'echarts';
	import {
		timeFormat
	} from '@/utils/timeCompute'
	export default {
		data() {
			return {
                type:'',
                title:'',
                date: '开始时间 ~ 结束时间',
                show: false,
                minDate: new Date(2010, 0, 1),
                maxDate: new Date(2050, 0, 31),
                result: ['全部'],
                pieColor : ['#FF6259','#FA6400','#F7B500','#2D9B8E','#0091FF','#B662FF'],
                cluesTable:[],
                toStoreTable:[],
                signTable:[],
                stickyOffset:50,
                popupShow:false,
            }
		},
		components: {
		},
		created() {
		},
        mounted() {
            console.log(this.$route.query);
            this.type = this.$route.query.type

            this.stickyOffset = document.querySelector('.topMenu').offsetHeight;

            if(this.type == '1'||this.type == '2'){
                this.title = '线索数据'
            }else if(this.type == '3'){
                this.title = '到店数据'
            }else{
                this.title = '签单数据'
            }

            // this.$route.query.reportData
            if(this.$route.query.reportData == 1 ){
                this.date = moment().format('YYYY-MM-DD')+'~'+moment().format('YYYY-MM-DD')
            }else if(this.$route.query.reportData == 2){
                this.date = moment().startOf('week').add(1,'days').format('YYYY-MM-DD')+'~'+moment().format('YYYY-MM-DD')
            }else if(this.$route.query.reportData == 3){
                this.date = moment().startOf('month').format('YYYY-MM-DD')+'~'+moment().format('YYYY-MM-DD')
            }else if(this.$route.query.reportData == 4){
                this.date = moment().startOf('year').format('YYYY-MM-DD')+'~'+moment().format('YYYY-MM-DD')
            }else if(this.$route.query.reportData == 6){
                this.date = moment().subtract(1,'day').format('YYYY-MM-DD')+'~'+moment().subtract(1,'day').format('YYYY-MM-DD');
            }

            this.getCluesEchart()
            this.getChannelEchart()
            this.getCluesTable()

            this.getToStoreEchart()
            this.getToStoreTable()
            this.getSignEchart()
            this.getSignTable()
		},
		methods: {
            //回退
            back () {
                this.$router.go(-1)
            },
            // 打开筛选
            rightClick(){
                this.popupShow = !this.popupShow
            },
            formatDate(date) {
                let month,day
                if((date.getMonth() + 1)<10){
                    month = '0'+ (date.getMonth() + 1)
                }else{
                    month = (date.getMonth() + 1)
                }
                if(date.getDate()<10){
                    day = '0'+date.getDate()
                }else{
                    day = date.getDate()
                }
                return `${date.getFullYear()}-${month}-${day}`;
            },
            onConfirm(date) {
                console.log(date);
                const [start, end] = date;
                this.show = false;
                this.date = `${this.formatDate(start)}~${this.formatDate(end)}`;
            },
            onResetting(){
                if(this.$route.query.reportData == 1 ){
                    this.date = moment().format('YYYY-MM-DD')+'~'+moment().format('YYYY-MM-DD')
                }else if(this.$route.query.reportData == 2){
                    this.date = moment().startOf('week').add(1,'days').format('YYYY-MM-DD')+'~'+moment().format('YYYY-MM-DD')
                }else if(this.$route.query.reportData == 3){
                    this.date = moment().startOf('month').format('YYYY-MM-DD')+'~'+moment().format('YYYY-MM-DD')
                }else if(this.$route.query.reportData == 4){
                    this.date = moment().startOf('year').format('YYYY-MM-DD')+'~'+moment().format('YYYY-MM-DD')
                }else if(this.$route.query.reportData == 6){
                    this.date = moment().subtract(1,'day').format('YYYY-MM-DD')+'~'+moment().subtract(1,'day').format('YYYY-MM-DD');
                }
                this.popupShow = false

                this.getCluesEchart()
                this.getChannelEchart()
                this.getCluesTable()

                this.getToStoreEchart()
                this.getToStoreTable()
                this.getSignEchart()
                this.getSignTable()
            },
            onAffirm(){
                if(this.date == '开始时间 ~ 结束时间'){ 
                    Toast.fail('请选择时间段');
                    return false
                }
                this.popupShow = false

                this.getCluesEchart()
                this.getChannelEchart()
                this.getCluesTable()

                this.getToStoreEchart()
                this.getToStoreTable()
                this.getSignEchart()
                this.getSignTable()
            },
            // 封装
            handleEcharts (name, option) {
                var chartDom = document.querySelector(name);
                // var chartDom = document.getElementById('main');
                var myChart = echarts.init(chartDom, 'night');
                option && myChart.setOption(option);
            },
            getCluesEchart() {
                let { type, startD, endD } = this.isOverTwoMonth(this.date)
                let params = {
                    Q_reportData_BW: startD+'~'+ endD,
                    marketCode:this.$route.query.marketCode,
                    type,

                }
                api.cluesEchartApi(params).then((res)=>{
                    console.log(res);
                    let data = res.data || []
                    this.$nextTick(() => {
                        this.handleTrend (data)
                    })
                })
            },
            //判断是否超过两个月
            isOverTwoMonth(date){
                let type 
                let d = date.split('~')
                let startD
                let endD
                if ((moment(d[1]).year() - moment(d[0]).year()) * 12 + moment(d[1]).month() - moment(d[0]).month() > 1) {
                    type = '3'
                    startD = moment(d[0]).format('YYYY-MM')
                    endD = moment(d[1]).format('YYYY-MM'); //当月
                } else {
                    type = '1'
                    startD = moment(d[0]).format('YYYY-MM-DD')
                    endD = moment(d[1]).format('YYYY-MM-DD'); //当天
                }
                return {
                    type,
                    startD,
                    endD
                }
            },
            // 趋势
            handleTrend (data) {
                let option = {
                    title: {
                        text: '单位:条',
                        textStyle: {
                            color: '#999999' ,
                            fontSize: 14,
                            fontWeight:'normal'
                        },
                        right:'5%',
                    },
                    tooltip: {
                        trigger: 'axis',
                        textStyle:{
                            align:'left'
                        }
                    },
                    legend: {
                        data: ['进线量', '总有效量', '当日有效'],
                        bottom:0
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        top:'15%',
                        bottom: '15%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: data.xAxis
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                        name: '进线量',
                        type: 'line',
                        data: data['进线量']
                        },
                        {
                        name: '总有效量',
                        type: 'line',
                        data: data['总有效量']
                        },
                        {
                        name: '当日有效',
                        type: 'line',
                        data: data['当日有效量']
                        },
                    ]
                };

                this.handleEcharts('.trendEchart', option)
            },

            getChannelEchart() {
                let params = {
                    type:'5',
                    selectType:this.type,
                    reportData1:this.date.split('~')[0],
                    reportData2:this.date.split('~')[1],
                    marketCode:this.$route.query.marketCode
                }
                api.canalRatioApi(params).then((res)=>{
                    console.log(res);
                    let data = res.data || []
                    this.$nextTick(() => {
                        this.handleOrderArea (data)
                    })
                })
            },
            // 渠道 pie图
            handleOrderArea(data) {
                if(data?.list){
                    data?.list.forEach((item,index) => {
                        item.itemStyle = {color:this.pieColor[index]}
                        item.bgColor = this.pieColor[index]
                    });
                }

                let option = {
                title: {
                    text:data?.sum || '0',
                    subtext: this.type == 1 ? "线索总量":'有效量',
                    left:'16%',
                    top:'42%',
                },
                tooltip: {
                    trigger: 'item',
                    textStyle:{
                        align:'left'
                    },
                    borderColor:'#fff'
                },
                legend: {
                    orient:'vertical',
                    top: '5%',
                    right: '5%',
                    itemGap:30,
                    icon:'circle',
                    itemHeight: 10,
                    selectedMode:false,
                    formatter: function (name) {
                        let arr = data.list.filter((item)=>{return item.name == name})
                        var arr1 = [
                            '{a|'+arr[0].name+'}',
                            '{b|'+arr[0].value+'}',
                            '{c|'+arr[0].rate+'}',
                        ]
                        return arr1.join('');
                    },
                    textStyle:{
                        rich:{
                            a:{
                                width:80,
                                fontSize:11,
                                color:'#8B959F',
                            },
                            b:{
                                width:80,
                                fontSize:12,
                                width:50,
                                fontWeight:600,
                                align:'left',
                            },
                            c:{
                                fontSize:12,
                                color:'#8B959F',
                            },
                        }
                    }
                },
                series: [
                    {
                    name: '',
                    type: 'pie',
                    radius: ['35%', '50%'],
                    left:'-54%',
                    avoidLabelOverlap: false,
                    itemStyle: {
                        // borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        // position: 'center'
                    },
                    emphasis: {
                        label: {
                        show: false,
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: data?.list
                    }
                ]
                };

                this.handleEcharts('.channelEchart', option)
            },

            getCluesTable(){
                let { type, startD, endD } = this.isOverTwoMonth(this.date)
                let params = {
                    Q_reportData_BW: startD+'~'+ endD,
                    marketCode:this.$route.query.marketCode,
                    type
                }
                api.cluesTableApi(params).then((res)=>{
                    console.log(res);
                    this.cluesTable = res.data || []
                })
            },

            // 到店
            getToStoreEchart() {
                let { type, startD, endD } = this.isOverTwoMonth(this.date)
                let params = {
                    Q_reportData_BW: startD + '~' + endD,
                    marketCode:this.$route.query.marketCode,
                    type
                }
                api.toStoreEchartApi(params).then((res)=>{
                    console.log(res);
                    let data = res.data || []
                    this.$nextTick(() => {
                        this.handleToStoreEchart (data)
                    })
                })
            },
            // 趋势
            handleToStoreEchart (data) {
                let option = {
                    title: {
                        text: '单位:条',
                        textStyle: {
                            color: '#999999' ,
                            fontSize: 14,
                            fontWeight:'normal'
                        },
                        right:'5%',
                    },
                    tooltip: {
                        trigger: 'axis',
                        textStyle:{
                            align:'left'
                        }
                    },
                    legend: {
                        data: ['到店量', '有效到店', '无效到店'],
                        bottom:0
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        top:'15%',
                        bottom: '15%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: data.xAxis
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                        name: '到店量',
                        type: 'line',
                        data: data['到店量']
                        },
                        {
                        name: '有效到店',
                        type: 'line',
                        data: data['有效到店']
                        },
                        {
                        name: '无效到店',
                        type: 'line',
                        data: data['无效到店']
                        },
                    ]
                };

                this.handleEcharts('.toStoreEchart', option)
            },
            getToStoreTable(){
                let { type, startD, endD } = this.isOverTwoMonth(this.date)
                let params = {
                    Q_reportData_BW: startD + '~' + endD,
                    marketCode:this.$route.query.marketCode,
                    type
                }
                api.toStoreTableApi(params).then((res)=>{
                    console.log(res);
                    this.toStoreTable = res.data || []
                })
            },

            // 签单
            getSignEchart(){
                let { type, startD, endD } = this.isOverTwoMonth(this.date)
                let params = {
                    Q_reportData_BW: startD + '~' + endD,
                    marketCode:this.$route.query.marketCode,
                    type
                }
                api.signEchartApi(params).then((res)=>{
                    console.log(res);
                    let data = res.data || []
                    this.$nextTick(() => {
                        this.handleSignEchart (data)
                    })
                })
            },
            // 趋势
            handleSignEchart (data) {
                let option = {
                    title: {
                        text: '单位:条',
                        textStyle: {
                            color: '#999999' ,
                            fontSize: 14,
                            fontWeight:'normal'
                        },
                        right:'5%',
                    },
                    tooltip: {
                        trigger: 'axis',
                        textStyle:{
                            align:'left'
                        }
                    },
                    legend: {
                        data: ['接单量', '签单量'],
                        bottom:0
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        top:'15%',
                        bottom: '15%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: data.xAxis
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                        name: '接单量',
                        type: 'line',
                        data: data['接单量']
                        },
                        {
                        name: '签单量',
                        type: 'line',
                        data: data['签单量']
                        }
                    ]
                };

                this.handleEcharts('.signEchart', option)
            },
            getSignTable(){
                let { type, startD, endD } = this.isOverTwoMonth(this.date)
                let params = {
                    Q_reportData_BW: startD + '~' + endD,
                    marketCode:this.$route.query.marketCode,
                    type
                }
                api.signTableApi(params).then((res)=>{
                    console.log(res);
                    this.signTable = res.data || []
                })
            },
        },
		
	}
</script>

<style scoped lang="scss">
.flex{
    display: flex;
}
.nav_b {
    background: #3975C6;
}
.screen{
    width: 36px;
    height: 34px;
    background: url('../../assets/screenIcon.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
}

.btn{
    width: 170px;
    height: 49px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 49px;
    text-align: center;
}
.btn1{
    margin-left: 10px;
    background: #4871C0;
    color: #FFFFFF;
}

.trend{
    height: 300px;
    margin-bottom: 15px;
    .title{
        width: 68px;
        height: 33px;
        background: url('../../assets/trendTBg.png') no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 28px;
        text-align: center;
    }
    .content{
        width: 100%;
        height:calc(100% - 33px);
        .trendEchart{
            width: 100%;
            height: 100%;
        }
    }
}

.channel{
    height: 300px;
    margin-bottom: 15px;
    .title{
        width: 100px;
        height: 33px;
        background: url('../../assets/indicatorsTBg.png') no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 28px;
        text-align: center;
    }
    .content{
        width: 100%;
        height:calc(100% - 33px);
        .channelEchart{
            width: 100%;
            height: 100%;
        }
    }
}
.table{
    // height: 300px;
    margin-bottom: 15px;
    .title{
        width: 100px;
        height: 33px;
        background: url('../../assets/rankIngTBg.png') no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 28px;
        text-align: center;
    }
    .content{
        width: 100%;
        height:calc(100% - 33px);
    }
}

.noneData{
        width: 100%;
        height: 280px;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
        img{
            width: 200px;
            height: 140px;
        }
        div{
            width: 100%;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            margin-top: 5px;
        }
    }

.my-popup-cover {
  background: rgba(0, 0, 0, 0.5);
}

.my-popup {
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;
  padding-top: 50px;
}

::v-deep{
    .van-dropdown-menu{
        position: absolute;
        top: -18px;
        right: 0px;
        // background-color:transparent
    }
    .van-dropdown-menu__bar{
        background-color:transparent
    }
    .van-dropdown-menu__title{
        display: none;
    }
    .van-calendar{
        color: #3e3e3e;
    }
    .van-cell{
        justify-content: space-between;
        padding: 20px 10px;
    }
    .van-cell__title, .van-cell__value{
        flex: none;
    }
    .van-checkbox{
        margin-bottom: 5px;
    }

    .el-table .el-table__header .cell{
        height: 38px;
        line-height: 38px;
    }

    .el-table th.el-table__cell{
        background: #F5F5F5;
    }
    .el-table .el-table__header .cell .caret-wrapper{
        width: 16px;
    }
    .el-table .el-table__body-wrapper .el-table__cell{
        font-size: 15px;
    }
    .el-table__fixed-right{
        height: 55px !important;
    }
    .el-table__fixed-right .el-table__cell{
        background: linear-gradient(180deg, #F5F5F5 0%, #FFFFFF 100%) !important;
    }
}
</style>
