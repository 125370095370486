<template>
    <div class="main_box">
        <headerNav title="消息详情" @leftClick="back"></headerNav>
        <div :class="customerDetails.productName?'height_style1':'height_style'">
            <div class="info_main">
                <div class="info_nav">
                    <div class="info_leftS">
                        <div class="info_leftS_tit1">【{{ customerDetails.consumerName }}】【{{customerDetails.contractNo}}】下单员分配</div>
                        <div class="info_leftS_tit2">
                            <div class="info_leftS_tit2L">合同号</div>
                            <div class="info_leftS_tit2R">{{ customerDetails.contractNo }}</div>
                        </div>
                    </div>
                    <div class="info_right">
                        <img src="../../assets/yifenpei.png" alt="" class="img" v-if="customerDetails.productName">
                    </div>
                </div>
                <div class="info_content">
                    <div class="cont_main" v-if="isSub">
                        <div class="cont_flex">
                            <div class="flex_a">客户姓名:</div>
                            <div class="flex_b">{{ customerDetails.consumerName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">合同号:</div>
                            <div class="flex_b">{{ customerDetails.contractNo }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">装修地址:</div>
                            <div class="flex_b">{{ customerDetails.detailAddress }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">面积:</div>
                            <div class="flex_b">{{ customerDetails.areaQty }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">房屋类型:</div>
                            <div class="flex_b">{{ customerDetails.houseStyle }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">套餐:</div>
                            <div class="flex_b">{{ customerDetails.templateName }}</div>
                        </div>
                    </div>
                    <div class="content_bottom" @click="handleChangeSub">
                        <div class="flex">
                            {{ isSub ? '收起更多' : '查看更多' }}
                            <img :class="isSub ? '' : 'jiantou'" :src="require('@/assets/sqgdpng.png')" alt=""
                                style="width:12px;height:12px;margin-left:4px">
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom_content">
                <div class="bottom_content_details">
                    <div class="bottom_content_details_left">补齐时间:</div>
                    <div class="bottom_content_details_right">{{customerDetails.supplyTime}}</div>
                </div>
                <div class="bottom_content_details">
                    <div class="bottom_content_details_left">预估金额:</div>
                    <div class="bottom_content_details_right">{{customerDetails.estimateAmount}}</div>
                </div>
                <div class="bottom_content_details">
                    <span class="bottom_content_details_left">工程合同价:</span>
                    <span class="bottom_content_details_right">{{customerDetails.contractPrice}}</span>
                </div>
                <div class="bottom_content_details">
                    <span class="bottom_content_details_left">缴费金额:</span>
                    <span class="bottom_content_details_right">{{customerDetails.collected}}</span>
                </div>
                <div class="bottom_content_details">
                    <span class="bottom_content_details_left">缴费比例:</span>
                    <span class="bottom_content_details_right">{{customerDetails.paymentRatio}}</span>
                </div>
                <div class="bottom_content_details">
                    <span class="bottom_content_details_left">工程结算价:</span>
                    <span class="bottom_content_details_right">{{customerDetails.settlementPrice}}</span>
                </div>
                <div class="bottom_content_details">
                    <span class="bottom_content_details_left">增减金额:</span>
                    <span class="bottom_content_details_right">{{customerDetails.increaseAmount}}</span>
                </div>
                <div class="bottom_content_details">
                    <span class="bottom_content_details_left">状态:</span>
                    <span class="bottom_content_details_right">{{customerDetails.orderProcess}}</span>
                </div>
                <div class="bottom_content_details">
                    <span class="bottom_content_details_left">实际开工日期:</span>
                    <span class="bottom_content_details_right">{{customerDetails.startDate}}</span>
                </div>
                <div class="bottom_content_details">
                    <span class="bottom_content_details_left">实际完工日期:</span>
                    <span class="bottom_content_details_right">{{customerDetails.realityDate}}</span>
                </div>
                <div class="bottom_content_details">
                    <span class="bottom_content_details_left">话务专员:</span>
                    <span class="bottom_content_details_right">{{customerDetails.telephonistName}}</span>
                </div>
                <div class="bottom_content_details">
                    <span class="bottom_content_details_left">设计师:</span>
                    <span class="bottom_content_details_right">{{customerDetails.transferName}}</span>
                </div>
                <div class="bottom_content_details">
                    <span class="bottom_content_details_left">督导:</span>
                    <span class="bottom_content_details_right">{{customerDetails.supervisorName}}</span>
                </div>
                <div class="bottom_content_details">
                    <span class="bottom_content_details_left">预审员:</span>
                    <span class="bottom_content_details_right">{{customerDetails.budgetName}}</span>
                </div>
                <div class="bottom_content_details" v-if="customerDetails.productName">
                    <span class="bottom_content_details_left">下单员:</span>
                    <span class="bottom_content_details_right">{{customerDetails.productName}}</span>
                </div>
            </div>
            <div class="bottom" v-if="!customerDetails.productName">
                <van-button type="info" class="bottom_btn" @click="handleSelectProduct">选择产品专员</van-button>
            </div>
            <van-action-sheet v-model="showSelectproduct" title="选择产品专员" :closeable="false">
                <!-- <template #description>
                    <div class="sheet1-top">
                        <van-field class="choose-input" v-model="searchValue" @input="handleInput" clearable
                            placeholder="请输入项目经理名字" center>
                            <template v-slot:right-icon>
                                <div style="height: 100%;display: flex;align-items: center;">
                                    <img src="../assets/search.png" class="search-icon" alt="">
                                </div>
                            </template>
                        </van-field>
                    </div>
                </template> -->
                <div class="content">
                    <div class="sheet-content sheet1-content">
                        <div :class="['sheet-item', action.activeValue == selectItem.id && 'sheet-item-active']"
                            v-for="selectItem in actions" :key="selectItem.id" @click="handleSelectChange(selectItem)">
                            {{ selectItem.idName }}
                            <img v-if="action.activeValue == selectItem.id" class="sheet-item-checked-icon"
                                src="@/assets/ischeck.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="sheet1-bottom">
                    <div class="sheet-cancel" @click="handleCancel">取消</div>
                    <div class="sheet-sure" @click="handleConfirm">确定</div>
                </div>
            </van-action-sheet>
        </div>
    </div>
</template>

<script>
import api from '@/api/api.js'
import api2 from '@/api/dataBoard.js'
import { Toast } from 'vant'


export default {
    components: {
    },
    data () {
        return {
            id: '',
            logId: '',
            messageId: '',
            orderNoticeType: '',
            isSub: false,
            dataTable: [],
            // 详情传值
            customerDetails: {},
            // 显示提交或者下单分包
            isShowPlace: '',
            // 销售单id
            customerId: '',
            // 消息内容
            msgConent: {},
            // 产品选择显示
            showSelectproduct: false,
            action: {
                activeValue:'',
                label:''
            }, // 已选择项目
            actions: [], // 选择数组
        }
    },
    props: {

    },
    methods: {
        back () {
            this.$router.go(-1)
        },

        // 切换收起展开
        handleChangeSub () {
            this.isSub = !this.isSub
        },
        // 查询详情
        async handleDetails () {
            // 产品专员数据
            const productList = await api.projuctCommissioner()
            this.actions = productList.data
            // 产品专员分配详情
            const data = await api.queryProductSpecialist({orderId:this.$route.params.orderInfoId,budgetId:this.$route.params.budgetId})
            this.customerDetails = data.data
            // 消息详情
            let res = await api2.messageApi(this.logId)
            this.msgConent = res.data
            await api.messageRead({ logId: this.logId, messageId: this.messageId, sendChannel: '0' })
        },
        // 产品选择弹出层
        handleSelectProduct () {
            this.showSelectproduct = true
        },
        // 选择产品
        handleSelectChange (item) {
            this.action.activeValue = item.id
            this.action.label = item.idName
            console.log(this.action.activeValue,item.id,this.action.activeValue == item.id)
        },
        // 取消选择
        handleCancel () {
            this.showSelectproduct = false
            this.action.activeValue = ''
            this.action.label = ''
        },
        // 确定
        async handleConfirm () {
            if(this.isSure) return
            if(!this.action.activeValue){
                Toast.fail('请选择产品专员')
                return
            }
            try{
                Toast.loading({
                    message: '分配中...',
                    forbidClick: true,
                });
                await api.distributioncommissioner({orderId:this.$route.params.orderInfoId,productId:this.action.activeValue})
                Toast.success('操作成功！')
                this.handleDetails()
            }finally{
                this.showSelectproduct = false
            }
        }
    },
    created () {
        this.id = this.$route.params.id
        this.logId = this.$route.params.logId
        this.messageId = this.$route.params.messageId
        this.handleDetails()
    }
}
</script>

<style lang="scss" scoped>
.main_box {
    background: #F5F5F5;
    height: calc(100vh - 51px);

    .color_blue {
        color: #3975C6;
    }

    .color_red {
        color: #EB5757;
    }

    .info_main {
        text-align: left;
        background: #3975C6;
        color: #fff;
        padding: 12px;
        font-family: PingFang SC-Heavy, PingFang SC;

        .info_nav {
            display: flex;
            align-items: center;

            .info_leftS {
                flex: 1;

                .info_leftS_tit1 {
                    font-size: 18px;
                    line-height: 21px;
                    font-weight: bold;
                }

                .info_leftS_tit2 {
                    margin-top: 10px;
                    height: 22px;
                    display: flex;
                    align-items: center;

                    .info_leftS_tit2L {
                        width: 41px;
                        height: 17px;
                        line-height: 17px;
                        font-size: 11px;
                        color: #3975C6;
                        background: #FFFFFF;
                        border-radius: 1px;
                        text-align: center;
                    }

                    .info_leftS_tit2R {
                        margin-left: 8px;
                        height: 21px;
                        font-size: 15px;
                    }
                }
            }

            .info_right {
                margin-left: 10px;
                width: 48px;
                height: 48px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .info_content {
            margin-top: 16px;

            .cont_main {
                border-radius: 4px;
                background: #2569C3;

                .cont_flex {
                    display: flex;
                    justify-content: space-between;
                    color: #FFFFFF;
                    padding: 8px;
                    font-family: PingFang SC-Regular, PingFang SC;

                    .flex_a {
                        width: 80px;
                        font-size: 14px;
                    }

                    .flex_b {
                        max-width: calc(100% - 80px);
                        font-size: 16px;
                        text-align: right;
                    }
                }

                .cont_flex_img {
                    display: flex;
                    justify-content: space-between;

                    .flex_text {
                        white-space: nowrap;
                    }

                    .flex_img {
                        display: flex;
                        flex-wrap: wrap;
                        width: 232px;

                        .img {
                            // display: inline-block;
                            margin: 0px 6px 6px 6px;
                        }
                    }
                }
            }

            .content_bottom {
                width: 100%;
                color: #ECF4FF;
                font-size: 12px;
                margin: 12px 0px 0px 0px;

                .flex {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: PingFang SC-Regular, PingFang SC;
                }

                .jiantou {
                    transform: rotate(180deg);
                    transition: all 0.3s;
                }
            }
        }

    }

    .height_style {
        height: calc(100vh - 130px);
        overflow: auto;
    }

    .height_style1 {
        height: calc(100vh - 49px);
        overflow: auto;
    }

    .bottom_content {
        margin-top: 16px;
        background: #fff;
        padding: 9px 12px;

        .bottom_content_details {
            color: #000000;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 500;
            margin: 18px 0;

            .bottom_content_details_left {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
            }

            .bottom_content_details_right {
                font-size: 15px;
            }

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    .bottom {
        position: fixed;
        bottom: 0;
        background: #FFFFFF;
        width: 100%;
        z-index: 2;
        color: #999999;
        font-family: PingFangSC-Regular, PingFang SC;
        box-shadow: 0px -1px 1px 0px #EEEEEE;

        .bottom_btn {
            background: #3975C6;
            color: #ffffff;
            width: 331px;
            border-radius: 5px;
            margin-bottom: 20px;
            margin-top: 5px;
        }
    }

    .content {
        padding-bottom: 45px;

        .sheet-content {
            background: #FFFFFF;

            .sheet-item {
                height: 56px;
                line-height: 56px;
                font-size: 16px;
                // font-family: Source Han Sans SC;
                font-weight: 400;
                color: #333333;
                text-align: left;
                margin: 0 15.5px;
                display: flex;
                align-items: center;
                position: relative;

                &:not(:last-child) {
                    border-bottom: 1px solid #E6E6E6;
                }

                .sheet-item-checked-icon {
                    width: 22px;
                    margin: auto 0;
                    position: absolute;
                    right: 11.5px;
                }
            }

            .sheet-item-active {
                color: #4F7AFD;
            }
        }
    }

    .sheet1-bottom {
        width: 100%;
        height: 52px;
        line-height: 52px;
        font-size: 16px;
        display: flex;
        position: absolute;
        bottom: 0;

        .sheet-cancel {
            flex: 1;
            padding: 0 16px;
            background-color: #fff;
            box-shadow: 0 0 4px 4px #eee;
        }

        .sheet-sure {
            flex: 1;
            padding: 0 16px;
            color: #fff;
            background-color: #4F7AFD;
        }
    }

}</style>