<template>
    <div>
        <van-sticky class="topMenu">
            <headerNav leftNav=" " class="nav_b" @leftClick="back" :title="this.title" :rightIcon="'screenIcon'" @rightClick="rightClick"></headerNav>
        </van-sticky>
        <div class="trend" v-if="this.type == '5'">
            <div class="title">趋势</div>
            <div class="content">
                <div class="transferOrderEchart" style="width: 100%;height: 100%;"></div>
            </div>
        </div>
        <div class="trend" v-if="this.type == '6'">
            <div class="title">趋势</div>
            <div class="content">
                <div class="completionEchart" style="width: 100%;height: 100%;"></div>
            </div>
        </div>
        <div class="table">
            <div class="title">数据表</div>
            <div class="content">
                <div v-if="this.type == '5'">
                    <el-table :data="transferOrderTable" style="width: 100%" v-if="transferOrderTable.length>0" v-sticky="{ top:stickyOffset, parent:'#table_box' }">
                        <el-table-column
                            prop="transferName"
                            label="设计师"
                            min-width="100">
                        </el-table-column>
                        <el-table-column
                            prop="department"
                            label="部门"
                            min-width="100">
                        </el-table-column>
                        <el-table-column
                            prop="sendAll"
                            label="接单量"
                            sortable
                            min-width="100">
                        </el-table-column>
                        <el-table-column
                            prop="transferSum"
                            label="转单量"
                            sortable
                            min-width="100">
                        </el-table-column>
                        <el-table-column
                            prop="transferValue"
                            label="转单产值"
                            sortable
                            align="right"
                            min-width="120">
                            <template slot-scope="scope">
                                <span class="numColor1">{{ parseFloat(scope.row.transferValue).toFixed(2) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="transferRateNum"
                            label="转单率"
                            sortable
                            min-width="100">
                            <template slot-scope="scope">
                                <span>{{ parseFloat(scope.row.transferRateNum).toFixed(2) }}%</span>
                            </template>
                        </el-table-column>
                        <el-table-column fixed='right' prop=" " label=" " width="10"></el-table-column>
                    </el-table>
                    <div class="noneData" v-else>
                        <img src="../../assets/none.png" alt="">
                        <div>暂无数据</div>
                    </div>
                </div>
                <div v-if="this.type == '6'">
                    <div class="btnTab" @click="tableChange">
                        {{ btnTab }} <van-icon name="exchange" color="#3975C6" />
                    </div>
                    <div v-if="showTable">
                        <el-table :data="completionTable" style="width: 100%" v-if="completionTable.length>0" v-sticky="{ top:stickyOffset, parent:'#table_box' }">
                            <el-table-column
                                prop="name"
                                label="设计师"
                                min-width="100">
                            </el-table-column>
                            <el-table-column
                                prop="department"
                                label="部门"
                                min-width="100">
                            </el-table-column>
                            <el-table-column
                                prop="polishingNum"
                                label="补齐量"
                                sortable
                                min-width="100">
                            </el-table-column>
                            <el-table-column
                                prop="polishingValue"
                                label="补齐产值"
                                sortable
                                align="right"
                                min-width="120">
                                <template slot-scope="scope">
                                    <span class="numColor1">{{ parseFloat(scope.row.polishingValue).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column fixed='right' prop=" " label=" " width="10"></el-table-column>
                        </el-table>
                        <div class="noneData" v-else>
                            <img src="../../assets/none.png" alt="">
                            <div>暂无数据</div>
                        </div>
                    </div>
                    <div v-else>
                        <el-table :data="completionTable1" style="width: 100%" v-if="completionTable1.length>0" v-sticky="{ top:stickyOffset, parent:'#table_box' }">
                            <el-table-column
                                prop="name"
                                label="督导"
                                min-width="100">
                            </el-table-column>
                            <el-table-column
                                prop="department"
                                label="部门"
                                min-width="100">
                            </el-table-column>
                            <el-table-column
                                prop="polishingNum"
                                label="补齐量"
                                sortable
                                min-width="100">
                            </el-table-column>
                            <el-table-column
                                prop="polishingValue"
                                label="补齐产值"
                                sortable
                                align="right"
                                min-width="120">
                                <template slot-scope="scope">
                                    <span class="numColor1">{{ parseFloat(scope.row.polishingValue).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column fixed='right' prop=" " label=" " width="10"></el-table-column>
                        </el-table>
                        <div class="noneData" v-else>
                            <img src="../../assets/none.png" alt="">
                            <div>暂无数据</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 筛选弹窗 -->
        <van-popup
            v-model="popupShow"
            class="my-popup"
            overlay-class="my-popup-cover"
            position="top"
        >
            <van-cell title="时间段：" :value="date" @click="show = true" />
            <van-calendar allow-same-day v-model="show" type="range" @confirm="onConfirm" color="#4871C0" :min-date="minDate" :max-date="maxDate"/>
            <van-cell title="部门：">
                <template #right-icon>
                    <van-checkbox-group v-model="result" direction="horizontal" ref="checkboxGroup">
                        <div v-for="(item,index) in depart" :key="index" class="checkbox">
                            <van-checkbox :name="item.id">{{ item.name }}</van-checkbox>
                        </div>
                    </van-checkbox-group>
                </template>
            </van-cell>
            <div class="flex" style="padding: 10px;justify-content: center;">
                <div class="btn" @click="onResetting">
                    重置
                </div>
                <div class="btn btn1" @click="onAffirm">
                    确认
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
	import api from '@/api/dataBoard.js'
    import moment from 'moment';
    import { Toast } from 'vant';
    import * as echarts from 'echarts';
	import {
		timeFormat
	} from '@/utils/timeCompute'
	export default {
		data() {
			return {
                type:'',
                title:'',
                date: '开始时间 ~ 结束时间',
                show: false,
                minDate: new Date(2010, 0, 1),
                maxDate: new Date(2050, 0, 31),
                result: [],
                depart:[
                    {name:'全部',id:'', flag:true,}
                ],
                transferOrderTable:[],
                completionTable:[],
                completionTable1:[],
                rankActive:'1',

                btnTab:'切换督导',
                showTable:true,
                stickyOffset:50,

                popupShow:false,
            }
		},
		components: {
		},
		created() {
		},
        mounted() {
            console.log(this.$route.query);
            this.stickyOffset = document.querySelector('.topMenu').offsetHeight;
            this.type = this.$route.query.type
            if(this.type == '5'){
                this.title = '转单数据'
            }else{
                this.title = '补齐数据'
            }
            if(this.$route.query.reportData == 1 ){
                this.date = moment().format('YYYY-MM-DD')+'~'+moment().format('YYYY-MM-DD')
            }else if(this.$route.query.reportData == 2){
                this.date = moment().startOf('week').add(1,'days').format('YYYY-MM-DD')+'~'+moment().format('YYYY-MM-DD')
            }else if(this.$route.query.reportData == 3){
                this.date = moment().startOf('month').format('YYYY-MM-DD')+'~'+moment().format('YYYY-MM-DD')
            }else if(this.$route.query.reportData == 4){
                this.date = moment().startOf('year').format('YYYY-MM-DD')+'~'+moment().format('YYYY-MM-DD')
            }else if(this.$route.query.reportData == 6){
                this.date = moment().subtract(1,'day').format('YYYY-MM-DD')+'~'+moment().subtract(1,'day').format('YYYY-MM-DD');
            }
            
            this.getdepart()
            this.getEchart1()
            this.getEchart2()
            this.getTransferOrderTable()
            this.getCompletionTable('1')
            this.getCompletionTable('2')
		},
		methods: {
            //回退
            back () {
                this.$router.go(-1)
            },
            // 打开筛选
            rightClick(){
                this.popupShow = !this.popupShow
            },
            formatDate(date) {
                let month,day
                if((date.getMonth() + 1)<10){
                    month = '0'+ (date.getMonth() + 1)
                }else{
                    month = (date.getMonth() + 1)
                }
                if(date.getDate()<10){
                    day = '0'+date.getDate()
                }else{
                    day = date.getDate()
                }
                return `${date.getFullYear()}-${month}-${day}`;
            },
            onConfirm(date) {
                console.log(date);
                const [start, end] = date;
                this.show = false;
                this.date = `${this.formatDate(start)}~${this.formatDate(end)}`;
            },
            onResetting(){
                if(this.$route.query.reportData == 1 ){
                    this.date = moment().format('YYYY-MM-DD')+'~'+moment().format('YYYY-MM-DD')
                }else if(this.$route.query.reportData == 2){
                    this.date = moment().startOf('week').add(1,'days').format('YYYY-MM-DD')+'~'+moment().format('YYYY-MM-DD')
                }else if(this.$route.query.reportData == 3){
                    this.date = moment().startOf('month').format('YYYY-MM-DD')+'~'+moment().format('YYYY-MM-DD')
                }else if(this.$route.query.reportData == 4){
                    this.date = moment().startOf('year').format('YYYY-MM-DD')+'~'+moment().format('YYYY-MM-DD')
                }else if(this.$route.query.reportData == 6){
                    this.date = moment().subtract(1,'day').format('YYYY-MM-DD')+'~'+moment().subtract(1,'day').format('YYYY-MM-DD');
                }
                this.result = []

                this.depart.forEach((item,index)=>{
                    item.flag = false
                    if(index == 0){
                        item.flag = true
                    }
                })
                this.popupShow = false
                this.result = ['']

                this.getEchart1()
                this.getEchart2()
                this.getTransferOrderTable()
                this.getCompletionTable('1')
                this.getCompletionTable('2')
            },
            onAffirm(){
                if(this.date == '开始时间 ~ 结束时间'){ 
                    Toast.fail('请选择时间段');
                    return false
                }
                let arr = []
                this.depart.forEach((item)=>{
                    if(item.flag){
                        arr.push(item.id)
                    }
                })
                this.result = arr
                this.popupShow = false

                this.getEchart1()
                this.getEchart2()
                this.getTransferOrderTable()
                this.getCompletionTable('1')
                this.getCompletionTable('2')
            },
            // 封装
            handleEcharts(name, option) {
                var chartDom = document.querySelector(name);
                // var chartDom = document.getElementById('main');
                var myChart = echarts.init(chartDom, 'night');
                option && myChart.setOption(option);
            },

            getEchart1(){
                let { type: dateType, startD, endD } = this.isOverTwoMonth(this.date)
                let params = {
                    Q_productionType_EQ:'1',
                    Q_reportData_BW: startD+'~'+endD,
                    marketCode:this.$route.query.marketCode,
                    departmentId:this.result.join(','),
                    type: dateType
                }
                api.transferOrderEchartApi(params).then((res)=>{
                    console.log(res);
                    let data = res.data || []
                    this.$nextTick(() => {
                        if(this.type == '5'){
                            this.handleEchart1(data)
                        }
                    })
                })
            },
            getEchart2(){
                let { type: dateType, startD, endD } = this.isOverTwoMonth(this.date)
                let params = {
                    Q_productionType_EQ:'2',
                    Q_reportData_BW: startD + '~' + endD,
                    marketCode:this.$route.query.marketCode,
                    departmentId:this.result.join(','),
                    type: dateType
                }
                api.transferOrderEchartApi(params).then((res)=>{
                    console.log(res);
                    let data = res.data || []
                    this.$nextTick(() => {
                        if(this.type == '6'){
                            this.handleEchart2(data)
                        }
                    })
                })
            },
            handleEchart1 (data) {
                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                        // type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        },
                        textStyle:{
                            align:'left'
                        }
                    },
                    toolbox: {
                        // feature: {
                        //   dataView: { show: true, readOnly: false },
                        //   magicType: { show: true, type: ['line', 'bar'] },
                        //   restore: { show: true },
                        //   saveAsImage: { show: true }
                        // }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        top:'15%',
                        bottom: '15%',
                        containLabel: true
                    },
                    legend: {
                        data: ['转单量', '转单产值'],
                        bottom:0
                    },
                    xAxis: [
                        {
                        type: 'category',
                        data: data.xAxis,
                        axisPointer: {
                            type: 'shadow'
                        }
                        }
                    ],
                    yAxis: [
                        {
                        type: 'value',
                        name: '单位:条',
                        },
                        {
                        type: 'value',
                        name: '单位:万元',
                        }
                    ],
                    series: [
                        {
                            name: '转单量',
                            type: 'bar',
                            color:'#EBBA00',
                            barMaxWidth:20,
                            data: data['转单量']
                        },
                        {
                            name: '转单产值',
                            type: 'line',
                            color:'#F4625A',
                            yAxisIndex: 1,
                            data: data['转单产值']
                        }
                    ]
                };

                this.handleEcharts('.transferOrderEchart', option)
            },
            handleEchart2 (data) {
                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                        // type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        },
                        textStyle:{
                            align:'left'
                        }
                    },
                    toolbox: {
                        // feature: {
                        //   dataView: { show: true, readOnly: false },
                        //   magicType: { show: true, type: ['line', 'bar'] },
                        //   restore: { show: true },
                        //   saveAsImage: { show: true }
                        // }
                    },
                    legend: {
                        data: ['补齐量', '补齐产值'],
                        bottom:0
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        top:'15%',
                        bottom: '15%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: data.xAxis,
                            axisPointer: {
                                type: 'shadow'
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '单位:条',
                        },
                        {
                            type: 'value',
                            name: '单位:万元',
                        }
                    ],
                    series: [
                        {
                            name: '补齐量',
                            type: 'bar',
                            color:'#EBBA00',
                            barMaxWidth:20,
                            data: data['补齐量']
                        },
                        {
                            name: '补齐产值',
                            type: 'line',
                            color:'#4871C0',
                            yAxisIndex: 1,
                            data: data['补齐产值']
                        }
                    ]
                };

                this.handleEcharts('.completionEchart', option)
            },

            getTransferOrderTable(){
                let { type: dateType, startD, endD } = this.isOverTwoMonth(this.date)
                let params = {
                    Q_reportData_BW: startD+'~'+endD,
                    marketCode:this.$route.query.marketCode,
                    departmentId:this.result.join(','),
                    type: dateType
                }
                api.transferOrderTableApi(params).then((res)=>{
                    console.log(res);
                    this.transferOrderTable = res.data || []
                })
            },
            getCompletionTable(type){
                let { type:dateType, startD, endD } = this.isOverTwoMonth(this.date)
                let params = {
                    selectType:type,
                    Q_reportData_BW: startD+'~'+endD,
                    marketCode:this.$route.query.marketCode,
                    departmentId:this.result.join(','),
                    type:dateType
                }
                api.completionTableApi(params).then((res)=>{
                    console.log(res);
                    if(type == '1'){
                        this.completionTable = res.data || []
                    }else{
                        this.completionTable1 = res.data || []
                    }
                })
            },

            getdepart(){
                let params = {
                    Q_marketCode_EQ:this.$route.query.marketCode,
                }
                api.departApi(params).then((res)=>{
                    res.data.forEach(item => {
                        item.flag = false
                    });
                    this.depart.push(...res.data)
                    console.log(this.depart)
                })
            },
            departmentCheckbox(e){
                e.flag = !e.flag
            },
            tableChange(){
                this.showTable = !this.showTable
                if(this.showTable){
                    this.btnTab = '切换督导'
                }else{
                    this.btnTab = '切换设计师'
                }
            },
            //判断是否超过两个月
            isOverTwoMonth (date) {
                let type
                let d = date.split('~')
                let startD
                let endD
                if ((moment(d[1]).year() - moment(d[0]).year()) * 12 + moment(d[1]).month() - moment(d[0]).month() > 1) {
                    type = '3'
                    startD = moment(d[0]).format('YYYY-MM')
                    endD = moment(d[1]).format('YYYY-MM'); //当月
                } else {
                    type = '1'
                    startD = moment(d[0]).format('YYYY-MM-DD')
                    endD = moment(d[1]).format('YYYY-MM-DD'); //当天
                }
                return {
                    type,
                    startD,
                    endD
                }
            },
        },
		
	}
</script>

<style scoped lang="scss">
.flex{
    display: flex;
}
.nav_b {
    background: #3975C6;
}
.screen{
    width: 36px;
    height: 34px;
    background: url('../../assets/screenIcon.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
}

.btn{
    width: 170px;
    height: 49px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 49px;
    text-align: center;
}
.btn1{
    margin-left: 10px;
    background: #4871C0;
    color: #FFFFFF;
}

.trend{
    height: 300px;
    margin-bottom: 15px;
    .title{
        width: 68px;
        height: 33px;
        background: url('../../assets/trendTBg.png') no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 28px;
        text-align: center;
    }
    .content{
        width: 100%;
        height:calc(100% - 33px);
        .trendEchart{
            width: 100%;
            height: 100%;
        }
    }
}

.channel{
    height: 300px;
    margin-bottom: 15px;
    .title{
        width: 100px;
        height: 33px;
        background: url('../../assets/indicatorsTBg.png') no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 28px;
        text-align: center;
    }
    .content{
        width: 100%;
        height:calc(100% - 33px);
        .channelEchart{
            width: 100%;
            height: 100%;
        }
    }
}

.btnTab{
    position: absolute;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #3975C6;
    right: 10px;
    top: 8px;
}
.table{
    // height: 300px;
    margin-bottom: 15px;
    position: relative;
    .title{
        width: 100px;
        height: 33px;
        background: url('../../assets/rankIngTBg.png') no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 28px;
        text-align: center;
    }
    .content{
        width: 100%;
        height:calc(100% - 33px);
    }
}

.noneData{
    width: 100%;
    height: 280px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    img{
        width: 200px;
        height: 140px;
    }
    div{
        width: 100%;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-top: 5px;
    }
}
.numColor1{
    color: #4871C0;
}

.department{
    flex-wrap: wrap;
    .checkbox{
        width: 30%;
        min-width: 80px;
        height: 36px;
        margin-left: 10px;
        margin-bottom: 10px;
        background: #F5F5F5;
        border-radius: 4px;
        text-align: center;
        line-height: 36px;
    }
    .active{
        width: 30%;
        min-width: 80px;
        height: 36px;
        margin-left: 10px;
        margin-bottom: 10px;
        background: #E7F1FF;
        border-radius: 4px;
        text-align: center;
        line-height: 36px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1677FF;
        position: relative;
    }
    .active::after{
        content: '';
        width: 21px;
        height: 18px;
        position: absolute;
        right: 0;
        bottom: 0;
        background: url('../../assets/btnActive.png') no-repeat center;
        background-size: 100% 100%;
    }
}

.my-popup-cover {
  background: rgba(0, 0, 0, 0.5);
}

.my-popup {
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;
  padding-top: 50px;
}

::v-deep{
    .van-dropdown-menu{
        position: absolute;
        top: -18px;
        right: 0px;
        // background-color:transparent
    }
    .van-dropdown-menu__bar{
        background-color:transparent
    }
    .van-dropdown-menu__title{
        display: none;
    }
    .van-calendar{
        color: #3e3e3e;
    }
    .van-cell{
        justify-content: space-between;
        padding: 20px 10px;
    }
    .van-cell__title, .van-cell__value{
        flex: none;
    }

    .van-checkbox__label{
        width: 60px;
        text-align: center;
    }

    .el-table .el-table__header .cell{
        height: 38px;
        line-height: 38px;
    }
    .el-table th.el-table__cell{
        background: #F5F5F5;
    }
    .el-table .el-table__header .cell .caret-wrapper{
        width: 16px;
    }
    .el-table .el-table__body-wrapper .el-table__cell{
        font-size: 15px;
    }
    .el-table__fixed-right{
        height: 55px !important;
    }
    .el-table__fixed-right .el-table__cell{
        background: linear-gradient(180deg, #F5F5F5 0%, #FFFFFF 100%) !important;
    }
}
</style>
