<template>
	<div class="filter-style">
		<div @click="clickFilter(item, index)" :style="index == 3 ? 'border:none' : ''"
			:class="item.dictValue == 'default' ? 'filter-item' : 'filter-item already-filter'"
			v-for="(item, index) in filterCondition">
			<span v-if="typeof (item.dictLabel) === 'string'" class="item-style">{{ item.dictLabel }}</span>
			<div v-else class="item-style-array item-style">
				<span>{{ item.dictLabel[0] }}</span>
				<span>{{ item.dictLabel[1] }}</span>
			</div>
			<span class="icon-style">
				<van-icon style="color: #AAAAAA;" name="arrow-down" />
			</span>
		</div>
		<!-- 选项弹窗 -->
		<van-action-sheet style="border-radius: 0;" v-model="sheetShow">
			<div class="content">
				<div class="sheet-top">
					<div class="sheet-cancel" @click="chooseCancel">取消</div>
					<div class="sheet-sure" @click="chooseSure">确定</div>
				</div>
				<div class="sheet-content">
					<div class="sheet-item" v-for="item in actions" @click="action = item">
						{{item.dictLabel}} 
						<img v-if="action.dictValue==item.dictValue" class="sheet-item-checked-icon"	src="@/assets/ischeck.png" alt="">
					</div>
				</div>
			</div>
		</van-action-sheet>
		<!-- 选项弹窗 -->
		<van-popup v-model="sheetShow1" round position="bottom" @click-overlay="chooseCancel1">
			<div class="content">
				<van-cascader v-model="cascaderValue" :title="selectTitle" :options="actions"
					:field-names="{ text: 'dictLabel', value: 'dictValue' }" active-color="#1677FF" :closeable="false"
					@change="chooseChange">
				</van-cascader>
				<div class="sheet-bottom">
					<div class="sheet-cancel" @click="chooseCancel1">取消</div>
					<div class="sheet-sure" @click="chooseSure1">确定</div>
				</div>
			</div>
		</van-popup>
		<!-- 时间选择 -->
		<van-action-sheet v-model="sheetTimeShow">
			<div>
				<van-datetime-picker @confirm="timeSubmit" @cancel="timeCancel" v-model="timeDate" type="date"
					title="选择时间" />
			</div>
		</van-action-sheet>
		<!-- 时间段选择 -->
		<van-action-sheet v-model="sheetTimeQuantumShow">
			<div>
				<div class="van-picker__toolbar"><button type="button" class="van-picker__cancel"
						@click="timeCancel">取消</button>
					<div class="van-ellipsis van-picker__title">选择时间</div><button type="button" class="van-picker__confirm"
						@click="timeSubmit">确认</button>
				</div>
				<van-tabs v-model="active" :lazy-render="false">
					<van-tab title="开始时间" name="startTime">
						<van-datetime-picker ref="startTime" class="datetime-picker-quantum" @confirm="timeSubmit"
							@cancel="timeCancel" v-model="startTime" type="date" title="选择时间" />
					</van-tab>
					<van-tab title="结束时间" name="endTime">
						<van-datetime-picker ref="endTime" :minDate="startTime" class="datetime-picker-quantum"
							@confirm="timeSubmit" @cancel="timeCancel" v-model="endTime" type="date" title="选择时间" />
					</van-tab>
				</van-tabs>

			</div>
		</van-action-sheet>
	</div>
</template>

<script>
import {
	timeFormat
} from '@/utils/timeCompute'
import selectTime from '@/components/selectTime.vue'
export default {
	data() {
		return {
			sheetShow: false,
			sheetShow1: false,
			action: {},
			actions: [],
			filterTypeId: '',
			filterCondition: [
				// {
				// 	typeId: 'type',//类型
				// 	dictValue: 'default',//默认值固定为default
				// 	dictLabel: 'label',//选中值文字
				// 	default: '施工状态',//默认值为default显示的文字
				// 	actions: [
				// 选项列表
				//{
				// 		dictValue: 'default',//选项值
				// 		dictLabel: '所有状态'//选项文字
				// 	}]
				// },
				// {
				// 	typeId: 'date',//date 选择时间 date-quantum 选择时间段
				// 	dictValue: 'default',
				// 	dictLabel: '开工日期',
				// 	default: '开工日期',
				// 	actions: [{
				// 		dictValue: 'default',
				// 		dictLabel: '所有日期'
				// 	}, {
				// 		dictValue: '1',
				// 		dictLabel: '自定义日期'
				// 	}]
				// },
			],
			sheetTimeShow: false,
			sheetTimeQuantumShow: false,
			timeDate: '',
			active: 'satrTime',
			startTime: '',
			endTime: '',
			cascaderValue: 'default',
			selectTitle: '',
		}
	},
	components: {
		selectTime
	},
	props: {
		filterList: {
			type: Array,
			default: () => []
		},
	},
	methods: {
		chooseCancel() {
			this.sheetShow = false;
		},
		chooseSure() {
			//选择时间
			if (this.action.dictValue != 'default' && this.filterTypeId == 'date') {
				this.sheetShow = false;
				this.sheetTimeQuantumShow = false
				this.sheetTimeShow = true
				return
			} else if (this.action.dictValue != 'default' && this.filterTypeId.indexOf('date-quantum') > -1) {
				// 选择时间段
				this.sheetShow = false;
				this.sheetTimeShow = false
				this.active = 'satrTime'
				this.sheetTimeQuantumShow = true
				return
			}
			for (let i = 0; i < this.filterCondition.length; i++) {
				if (this.filterCondition[i].typeId == this.filterTypeId) {
					// 将选项值和选项文字绑定
					this.filterCondition[i].dictValue = this.action.dictValue
					// 如果是默认值default 将默认文字重置为默认文字
					this.filterCondition[i].dictLabel = this.action.dictValue == 'default' ? this.filterCondition[i]
						.default :
						this.action.dictLabel
					//判断如果是时间或时间段类型 重置当前时间
					if (this.action.dictValue == 'default' && (this.filterTypeId == 'date' || this.filterTypeId.indexOf('date-quantum') > -1)) {
						this.timeDate = this.startTime = this.endTime = new Date()
					}
					break
				}
			}
			//返回筛选参数
			this.$emit('filterFunction', this.filterCondition)
			this.sheetShow = false;
		},
		chooseCancel1(){
			this.cascaderValue = this.filterCondition[1].dictValue
			this.sheetShow1 = false;
		},
		chooseSure1() {
			this.sheetShow1 = false;
			this.chooseSure()
		},
		chooseChange({ selectedOptions }) {
			this.action = selectedOptions.at(-1)
		},
		//当前选中状态
		clickFilter(item, index) {
			if(item.type === 'Cascader') {
				this.sheetShow1 = true
				this.selectTitle = item.selectTitle
			}else{
				this.sheetShow = true
			}
			this.action = item
			this.actions = item.actions
			this.filterTypeId = item.typeId
		},
		//时间选择提交
		timeSubmit(val) {
			for (let i = 0; i < this.filterCondition.length; i++) {
				if (this.filterCondition[i].typeId == this.filterTypeId) {
					this.filterCondition[i].dictValue = this.action.dictValue
					this.filterCondition[i].dictLabel = this.action.dictValue == 'default' ? this.filterCondition[i]
						.default :
						this.action.dictLabel
					//选择单个时间
					if (this.action.dictValue != 'default' && this.filterTypeId == 'date') {
						this.filterCondition[i].dictLabel = timeFormat(val, '-')
					}
					//选择时间段
					if (this.action.dictValue != 'default' && this.filterTypeId.indexOf('date-quantum') > -1) {
						// console.log(this.$refs.startTime.getPicker().getValues())
						let start = this.$refs.startTime.getPicker().getValues().join('-')
						let end = this.$refs.endTime.getPicker().getValues().join('-')
						let timeArray = [start, end]
						this.filterCondition[i].dictLabel = timeArray
						// this.filterCondition[i].dictLabel = timeArray.join('~')

					}
					break
				}
			}
			this.$emit('filterFunction', this.filterCondition)
			this.sheetTimeShow = false
			this.sheetTimeQuantumShow = false
		},
		//时间类型取消选择
		timeCancel(val) {
			this.sheetTimeShow = false
			this.sheetTimeQuantumShow = false
		},
	},
	created() {
		this.startTime = this.endTime = new Date()
		this.filterCondition = this.filterList
	},
	mounted() {
		this.filterCondition = this.filterList
	},
}
</script>

<style scoped lang="scss">
.filter-style {
	height: 40px;
	background: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1px;
	font-size: 10px;

	.filter-item:last-child {
		border-right: none;
	}

	.filter-item {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-right: 1px solid #E6E6E6;

		.item-style {
			width: 60px;
			// display: inline-block;
			// width: 50px;
			// max-width: 90px;
			margin-right: 4px;
		}

		.item-style-array {
			display: flex;
			flex-direction: column,
		}

		.icon-style {

			// display: inline-block;
			// height: 40px;
			// line-height: 40px;
		}
	}

	.already-filter {
		color: #4F7AFD;
		display: flex;
	}

	.content {
		.sheet-top {
			position: relative;
			height: 45px;
			line-height: 45px;
			background: #F5F6F8;
			font-size: 16px;
			// font-family: Source Han Sans SC;
			font-weight: 400;

			.sheet-sure,
			.sheet-cancel {
				// width: 50%;
			}

			.sheet-cancel {
				position: absolute;
				left: 28px;
				color: #999999;
			}

			.sheet-sure {
				position: absolute;
				right: 28px;
				color: #4F7AFD;
			}
		}

		.sheet-content {
			background: #FFFFFF;
			max-height: calc(60vh - 126px);
			padding-bottom: 81px;
			overflow-y: auto;

			.sheet-item {
				height: 45px;
				line-height: 45px;
				font-size: 16px;
				// font-family: Source Han Sans SC;
				font-weight: 400;
				color: #333333;
				text-align: left;
				margin: 0 15.5px;
				border-bottom: 1px solid #E6E6E6;
				display: flex;
				align-items: center;
				position: relative;

				.sheet-item-checked-icon {
					width: 22px;
					margin: auto 0;
					position: absolute;
					right: 11.5px;
				}
			}
		}

		.sheet-bottom {
			height: 50px;
			line-height: 50px;
			background: #F5F6F8;
			font-size: 16px;
			font-weight: 400;
			display: flex;

			.sheet-sure,
			.sheet-cancel {
				width: 50%;
			}

			.sheet-cancel {
				color: #333;
			}

			.sheet-sure {
				background-color: #3975C6;
				color: #fff;
			}
		}

		::v-deep .van-cascader__header {
			justify-content: center;
		}
	}
}
</style>
<style lang="scss">
.filter-style {
	.datetime-picker-quantum {
		.van-picker__toolbar {
			display: none;
		}
	}
}
</style>
