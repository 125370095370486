<template>
	<div>
		<div v-if="show">
			<headerNav leftNav="返回" title="客户详情" @leftClick="back"></headerNav>
			<div class="content-box">
				<div class="content-item">
					<div class="item-top">
						<div>
							<img class="content-img" src="../../../assets/tx.png" alt="">
						</div>
						<div class="content-text">
							<div class="text-name">{{info.consumerName}}</div>
							<div class="text-phone">电话：{{info.phoneNo}}</div>
						</div>
					</div>
					<div class="item-bottom">
						<div>装修地址 <span>{{info.detailAddress}}</span> </div>
						<div>套餐类型 <span>{{packageTypeInfo.allianceName}}</span></div>
						<div>施工合同 <span>{{info.finaleContract}}</span></div>
					</div>
				</div>
				<div class="clientsystem">
					<div class="clientsystem-title">跟单人员</div>
					<div v-if="swipePaging.length<1" class="null-style">无跟单人员</div>
					<div v-else>
						<van-swipe :loop="false" class="my-swipe" indicator-color="white">
							<van-swipe-item v-for="(item1,index1) in swipePaging" :key="index1">
								<div class="swope-page" >
									<div class="swope-page-box" v-for="(item2,index2) in item1">
										<img class="swope-page-box-tx" src="../../../assets/tx.png" alt="">
										<div class="swope-page-box-name">{{item2.name}}</div>
										<div class="swope-page-box-bm">【{{item2.departmentName}}】</div>
									</div>
								</div>
							</van-swipe-item>
						</van-swipe>
					</div>
				</div>
				<div class="clientsystem">
					<div class="clientsystem-title">客户资料</div>
					<div class="clientsystem-box">
						<div class="clientsystem-item" v-for="(item,index) in modelList3" @click="joinModel(item)">
							<img :src="item.imgUrl" class="clientsystem-img" alt="">
							<div class="clientsystem-text">{{item.title}}</div>
						</div>
					</div>
				</div>
				<div class="clientsystem">
					<div class="clientsystem-title">工程管理</div>
					<div class="clientsystem-box">
						<div :class="['clientsystem-item',info.statusCode==='未开工'&&item.title==='工程进度'&&'clientsystem-item1']" v-for="(item,index) in modelList1" @click="joinModel(item)">
							<img :src="info.statusCode==='未开工'&&item.title==='工程进度'? item.imgUrl1: item.imgUrl" class="clientsystem-img" alt="">
							<div class="clientsystem-text">{{item.title}}</div>
						</div>
						<div class="clientsystem-item"></div>
						<div class="clientsystem-item"></div>
					</div>
				</div>
				<div class="clientsystem clientsystem2">
					<div class="clientsystem-title">项目申报单</div>
					<div class="clientsystem-box">
						<div class="clientsystem-item" v-for="(item,index) in modelList2">
							<img :src="item.imgUrl" class="clientsystem-img" alt="">
							<div class="clientsystem-text">{{item.title}}</div>
						</div>
						<div class="clientsystem-item"></div>
						<div class="clientsystem-item"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import api from '@/api/api.js'
	export default {
		data() {
			return {
				info: {},
				show: true,
				modelList1: [{
						imgUrl: require('../../../assets/gcjd2.png'),
						imgUrl1: require('../../../assets/gcjd4.png'),
						title: '工程进度',
						toRouterName: 'deepenProjectPlanName'
					},
					{
						imgUrl: require('../../../assets/clxq.png'),
						title: '材料详情',
						toRouterName: ''
					},
					{
						imgUrl: require('../../../assets/xjzg.png'),
						title: '巡检整改',
						toRouterName: 'deepenPatrolListlName'
					},
					{
						imgUrl: require('../../../assets/shfk.png'),
						title: '售后反馈',
						toRouterName: ''
					}
				],
				modelList2: [{
					imgUrl: require('../../../assets/clzy.png'),
					title: '材料转移',
					toRouterName: ''
				}],
				modelList3: [{
						imgUrl: require('../../../assets/uf1.png'),
						title: '量房信息',
						toRouterName: 'deepenUserInfoRoomInfo'
					},
					{
						imgUrl: require('../../../assets/uf2.png'),
						title: '设计图纸',
						toRouterName: 'deepenUserInfoDesignDrawing'
					},
					{
						imgUrl: require('../../../assets/uf3.png'),
						title: '预算单',
						toRouterName: 'deepenUserInfoBudgetSheet'
					},
					{
						imgUrl: require('../../../assets/uf4.png'),
						title: '选材单',
						toRouterName: 'deepenUserInfoMaterialSheet'
					},
					{
						imgUrl: require('../../../assets/uf5.png'),
						title: '文档管理',
						toRouterName: 'deepenUserInfoFileManage'
					},
					{
						imgUrl: require('../../../assets/uf6.png'),
						title: '合同管理',
						toRouterName: 'deepenUserInfoContractManage'
					},
				],
				tailAfterPeopleList: [
					// {
					// 	name: '跟单员1',
					// 	department: '1',
					// 	departmentName: '督导部',
					// 	imgUrl: require('../../../assets/tx.png')
					// },
					// {
					// 	name: '跟单员2',
					// 	department: '2',
					// 	departmentName: '技术部',
					// 	imgUrl: require('../../../assets/tx.png')
					// },
					// {
					// 	name: '跟单员3',
					// 	department: '3',
					// 	departmentName: '销售部',
					// 	imgUrl: require('../../../assets/tx.png')
					// },
					// {
					// 	name: '跟单员4',
					// 	department: '1',
					// 	departmentName: '督导部',
					// 	imgUrl: require('../../../assets/tx.png')
					// },
					// {
					// 	name: '跟单员5',
					// 	department: '4',
					// 	departmentName: '监察部',
					// 	imgUrl: require('../../../assets/tx.png')
					// },
					// {
					// 	name: '跟单员6',
					// 	department: '1',
					// 	departmentName: '督导部',
					// 	imgUrl: require('../../../assets/tx.png')
					// },
				],
				swipePaging: [],
				packageTypeInfo:{},//套餐信息
			}
		},
		methods: {
			back() {
				// this.$router.go(-1)
				this.$router.push({
					name: "deepenConstructionName",
					query: this.info
				})
			},
			toFollowList() {
				this.$router.push({
					name: "followListChildrenName",
					query: this.info
				})
			},
			toFollowHistory() {
				this.$router.push({
					name: "followHistoryChildrenName",
					query: this.info
				})

			},
			toClientInfo() {
				this.$router.push({
					name: "clientInfoChildrenName",
					query: this.info
				})
			},
			cancelCancel() {
				this.show = true
			},
			inviteSure() {
				this.show = true
			},
			//进入模块功能
			joinModel(item){
				sessionStorage.setItem('fromRouterNameDeepen','deepenClicntDetailName')
				if(item.toRouterName=='' || (this.info.statusCode==='未开工'&&item.title==='工程进度')) return
				sessionStorage.setItem('clientInfo',JSON.stringify(this.info))
				sessionStorage.setItem('clientTypeInfo',JSON.stringify(this.packageTypeInfo))
				this.$router.push({
					name: item.toRouterName,
				})
			},
			//将数组分割 array分割的数组 subGroupLength分割长度
			getswipePaging(array, subGroupLength) {
				let index = 0;
				let newArray = [];
				while (index < array.length) {
					newArray.push(array.slice(index, index += subGroupLength));
				}
				this.swipePaging = newArray;
			},
			//查询套餐类型
			async getPackageType(){
				let res = await api.getPackageType(this.info.orderId)
				this.packageTypeInfo = res.data || {}
			},
			//获取跟单人员
			async getFollowPeopleList(){
				let obj = {
					Q_orderId_EQ:this.info.orderId
				}
				let res = await api.getFollowPeopleList(obj)
				this.tailAfterPeopleList = []
				let followPeople = {}
				if(res.data&&res.data.length>0){
					followPeople = res.data[0]
					if(followPeople.signId) this.tailAfterPeopleList.push({name: followPeople.signName,departmentName: '签单员'})
					if(followPeople.transferId) this.tailAfterPeopleList.push({name: followPeople.transferName,departmentName: '转单员'})
					if(followPeople.supervisorId) this.tailAfterPeopleList.push({name: followPeople.supervisorName,departmentName: '督导专员'})
					if(followPeople.budgetId) this.tailAfterPeopleList.push({name: followPeople.budgetName,departmentName: '预算员'})
					if(followPeople.productId) this.tailAfterPeopleList.push({name: followPeople.productName,departmentName: '下单员'})
					if(followPeople.deepenId) this.tailAfterPeopleList.push({name: followPeople.deepenName,departmentName: '深化专员'})
					if(followPeople.projectId) this.tailAfterPeopleList.push({name: followPeople.projectName,departmentName: '项目经理'})
					if(followPeople.productManagerId) this.tailAfterPeopleList.push({name: followPeople.productManagerName,departmentName: '产品经理'})
				}else{
					this.tailAfterPeopleList = []
				}
				this.getswipePaging(this.tailAfterPeopleList,5)
			}
		},
		mounted() {
			this.info = JSON.parse(sessionStorage.getItem('clientInfo'))
			this.getPackageType()
			this.getFollowPeopleList()
		},
	}
</script>

<style scoped lang="scss">
	.header-style {
		height: 49px;
		background: #4873C0;
		display: flex;
		position: relative;

		div {
			width: 33.33%;
		}

		.header-title {
			line-height: 49px;
			font-size: 18.5px;
			// font-family: Source Han Sans SC;
			font-weight: 400;
			color: #FFFFFF;
			width: 100%;
			text-align: center;
		}

		.header-add {
			line-height: 49px;
			font-size: 16px;
			// font-family: Source Han Sans SC;
			font-weight: 400;
			color: #FFFFFF;
			text-align: right;
			padding-right: 16.5px;
			position: absolute;
			right: 0;
		}

		.header-back {
			line-height: 49px;
			font-size: 16px;
			// font-family: Source Han Sans SC;
			font-weight: 400;
			color: #FFFFFF;
			text-align: left;
			padding-left: 16.5px;
			position: absolute;
			left: 0;

			.header-back-icon {
				// font-size: 40px;
				margin-right: 12px;
			}
		}
	}

	.content-box {
		height: calc(100vh - 65px);
		padding: 10px 10px 0 10px;
		overflow: auto;

		// border-bottom: 2px solid red;
		.content-item {
			height: 165px;
			background: #FFFFFF;
			border-radius: 6px;
			margin-bottom: 10px;
		}

		.item-top {
			display: flex;
			height: 38px;
			border-bottom: 1px solid #E6E6E6;
			padding: 7.5px 10px;
			align-items: center;
			position: relative;

			.content-img {
				width: 38px;
				height: 38px;
			}

			.content-text {
				margin-left: 10.5px;
				height: 43px;

				.text-name {
					text-align: left;
					line-height: 22.5px;
					font-size: 16px;
					// font-family: Source Han Sans SC;
					font-weight: 400;
					color: #333333;
				}

				.text-phone {
					text-align: left;
					line-height: 20px;
					font-size: 11px;
					// font-family: Source Han Sans SC;
					font-weight: 400;
					color: #777777;
				}
			}
		}

		.item-bottom {
			font-size: 15px;
			text-align: left;
			font-weight: 400;
			color: #777777;
			line-height: 15px;
			padding: 0 13px;

			div {
				margin: 18px 0 16px;

				span {
					font-size: 15px;
					font-weight: 400;
					color: #333333;
					line-height: 15px;
					margin-left: 18px;
				}
			}

		}
	}

	.clientsystem:nth-child(3) {
		margin-top: 10px;

	}

	.clientsystem:nth-child(2) {
		height: 157px;
	}

	.clientsystem {
		// width: 710px;
		// height: 140.5px;
		background: #FFFFFF;
		border-radius: 6px;
		&:not(:first-child){
			margin-top: 10px;
		}

		.clientsystem-title {
			line-height: 12.5px;
			font-size: 13px;
			// font-family: Source Han Sans SC;
			font-weight: 400;
			color: #777777;
			text-align: left;
			padding: 13.5px 0 13px 10px;
			border-bottom: 1px solid #E6E6E6;
		}
		.null-style{
			font-size: 18px;
			line-height: 120px;
		}
		.clientsystem-box {
			display: flex;
			flex-wrap: wrap;

			// height: 101px;
			// align-items: center;
			.clientsystem-item {
				width: 117px;
				height: 101px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				border-right: 1px solid #E6E6E6;
				border-bottom: 1px solid #E6E6E6;

				.clientsystem-img {
					width: 31px;
					height: 31px;
				}

				.clientsystem-text {
					line-height: 13px;
					font-size: 14px;
					// font-family: Source Han Sans SC;
					font-weight: 400;
					color: #333333;
					margin-top: 15px;
				}
			}

			.clientsystem-item:nth-child(n+4) {
				border-bottom: none;
			}

			.clientsystem-item:nth-child(3n+0) {
				border-right: none;
			}
			.clientsystem-item1{
				opacity: 0.5;
			}
		}
	}

	.clientsystem2 {
		margin-top: 10px;

		.clientsystem-box {
			.clientsystem-item:nth-child(n+0) {
				border-bottom: none;
			}

			.clientsystem-item:nth-child(3n+0) {
				border-right: none;
			}
		}

	}

	.my-swipe {
		.swope-page {
			display: flex;

			.swope-page-box {
				display: flex;
				flex-direction: column;
				width: 71px;
				height: 106px;
				align-items: center;
				justify-content: center;

				.swope-page-box-tx {
					width: 40px;
					height: 40px;
					margin-bottom: 6px;
				}

				.swope-page-box-name,
				.swope-page-box-bm {
					font-size: 11px;
					font-family: SourceHanSansSC-Regular, SourceHanSansSC;
					font-weight: 400;
					color: #777777;
					line-height: 13px;
				}
			}
		}
	}
</style>
<style lang="scss">
	.my-swipe .van-swipe-item {
		height: 118px;
		width: 100%;
		color: #fff;
		font-size: 20px;
		line-height: 150px;
		text-align: center;
		/* background-color: #39a9ed; */
	}

	.my-swipe .van-swipe__indicators .van-swipe__indicator {
		width: 17px;
		height: 2px;
		background: #CCCCCC;
		border-radius: 1px 1px 1px 1px;
		opacity: 1;
		display: inline-block;
		margin-right: 0 !important;
	}

	.my-swipe .van-swipe__indicators .van-swipe__indicator--active {
		width: 17px;
		height: 2px;
		background: #4F7AFD !important;
		border-radius: 1px 1px 1px 1px;
		opacity: 1;
		display: inline-block;
	}
</style>