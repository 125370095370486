<template>
    <div class="patrolList-style">
        <filterModel2 :filterList="filterCondition" @filterFunction="onRefresh" v-if="info.type == '1'"></filterModel2>
        <filterModel :filterList="filterCondition" @filterFunction="onRefresh" v-else></filterModel>

        <div class="content-style">
            <van-list class="vant-list-style" v-model="loading" :finished="finished"
                :finished-text="total?'·已加载全部数据·':'·暂无数据·'" @load="onLoad">
                <pullRefresh ref="pullRefresh" @refresh="onRefresh">
                    <div class="list-item" v-for="item in list">
                        <div class="header-date">
                            {{getIsToday(item.createDate).slice(0,10)}}
                        </div>
                        <div class="center-content">
                            <img src="@/assets/tx.png" class="tx-img" alt="">
                            <div class="content-info">
                                <div class="info-time">{{ item.createDate.slice(11,16)}}</div>
                                <div class="info-name">
                                    {{item.createId}}
                                </div>
                                <div class="info-item">
                                    <div class="info-title">巡检时间：</div>
                                    <div class="info-text">{{item.inspectionTime.slice(0,10)}}</div>
                                </div>
                                <div class="info-item">
                                    <div class="info-title">巡检阶段：</div>
                                    <div class="info-text">{{item.nodeName}}</div>
                                </div>
                                <div class="info-item">
                                    <div class="info-title">客户跟随：</div>
                                    <div class="info-text">{{item.isFollow==1?'是':'否'}}</div>
                                </div>
                                <div class="info-item">
                                    <div class="info-title">巡检说明：</div>
                                    <div class="info-text">{{item.inspectionRemark}}</div>
                                </div>
                                <div class="info-item">
                                    <div class="info-title">巡检详情：</div>
                                    <div class="info-text info-text2" @click="toPatrolDetails(item)">
                                        <div>总巡检项·{{item.inspectionQty}}；</div>
                                        <div>现存隐患·{{item.existQty}}；</div>
                                        <div>解决隐患·{{item.finishQty|| 0}}；</div>
                                        <div>符合标准·{{item.satisfyQty}}；</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </pullRefresh>
            </van-list>
        </div>


    </div>
</template>

<script>
	import filterModel from '@/components/filter.vue';
	import filterModel2 from '@/components/filterModel2.vue';
	import {
		getBeforeDate,
		isToday
	} from '@/utils/timeCompute'
	import api from '@/api/api.js'
	export default {
		data() {
			return {
				sheetShow: false,
				filterCondition: [{
						typeId: 'xjjd',
						type: 'Cascader',
						selectTitle: '选择巡检阶段',
						dictValue: 'default',
						dictLabel: '巡检阶段',
						default: '巡检阶段',
						actions: [{
							dictValue: 'default',
							dictLabel: '所有阶段'
						}, ]
					},
					{
						typeId: 'date-quantum',
						dictValue: 'default',
						dictLabel: '巡检时间',
						default: '巡检时间',
						actions: [{
								dictValue: 'default',
								dictLabel: '所有时间'
							},
							{
								dictValue: '1',
								dictLabel: '自定义时间'
							},
						]
					}
				],
				list: [],
				isLoading: false,
				loading: false,
				finished: false,
				error: false, // 是否加载失败
				pageNum: 1, // 分页
				pageSize: 10, // 每页条数
				total: 0, // 数据总条数
				info:{}
			}
		},
		components: {
			filterModel,
			filterModel2
		},
		methods: {
			//获取列表
			async getlist(e) {
				let obj = {
					page: this.pageNum,
					limit: this.pageSize,
					Q_projectId_EQ: this.info.id,
					Q_isDel_EQ:'0',
					Q_inspectionTime_BW:''
				}
				if (this.info.type == '1') {
					obj['stageId'] = ''
				} else {
					obj['Q_nodeCode_EQ'] = ''
				}
				if (e) {
					let d = []
					if(e[1].dictValue != "default"){
						d[0] = e[1].dictLabel[0]+' 00:00:00'
						d[1] = e[1].dictLabel[1]+' 23:59:59'
					}
					if(this.info.type == '1'){
						if(e[0].dictValue == "default"){
							obj.stageId = ''
						}else{
							const obj1 = this.findActiveAction(this.filterCondition[0].actions, e[0].dictValue, 'dictValue')
							obj.stageId = obj1.id
						}
					}else{
						obj.Q_nodeCode_EQ = (e[0].dictValue == "default") ? '' : e[0].dictValue
					}
					obj.Q_inspectionTime_BW = (e[1].dictValue == "default") ? '' : d.join('~')
				}
				let res = {}
				if(this.info.type == '1'){
					res = await api.getPatrolRecordListNew(obj)
				}else{
					res = await api.getPatrolRecordList(obj)
				}
				if (res.code == 200) {
					this.total = res.total;
					if (res.data.length === 0) { // 判断获取数据条数若等于0
						this.list = []; // 清空数组
						this.finished = true; // 停止加载
					} else {
						// 若数据条数不等于0
						this.list.push(...res.data) // 将数据放入list中
						this.loading = false; // 加载状态结束 
						// 如果list长度大于等于总数据条数,数据全部加载完成
						if (this.list.length >= res.count) {
							this.finished = true; // 结束加载状态
						}
					}
					if(this.$refs.pullRefresh){
						this.$refs.pullRefresh.refreshSuccess()
					}
				}

			},
			// 被 @load调用的方法
			onLoad(e) { // 若加载条到了底部
				let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
					// this.getList(); // 调用上面方法,请求数据
					this.getlist(e)
					this.pageNum++; // 分页数加一
					this.finished && clearTimeout(timer); //清除计时器
				}, 100);
			},
			// 加载失败调用方法
			onRefresh(e) {
				this.finished = false; // 清空列表数据
				this.loading = true; // 将 loading 设置为 true，表示处于加载状态
				this.pageNum = 1; // 分页数赋值为1
				this.list = []; // 清空数组
				this.onLoad(e); // 重新加载数据
			},
			getIsToday(s) {
				return isToday(s)
			},
			// 查询所选定的action方法
			findActiveAction(list, key, keyText) {
				let obj2 = {}
				if(list && list.length){
					list.forEach(item => {
						if(item[keyText] === key){
							obj2 = item;
							return;
						}
						if(item.children && item.children.length){
							const obj3 = this.findActiveAction(item.children, key, keyText)
							if(obj3[keyText]){
								obj2 = obj3
							}
						}
					})
				}
				return obj2;
			},
			//巡检阶段
			async getProjectInspectList() {
				if(this.info.type == '1'){
					let obj = { id: this.info.id }
					let res = await api.projectInfoNodeInfoById(obj)
					if (res.code == 200) {
						let temp = []
						if(res.data.nodeList?.length){
							res.data.nodeList.forEach(e => {
								let children = null
								if(e.children?.length){
									children = e.children.map(e1=>({ dictValue: e1.nodeCode, dictLabel: e1.nodeName, id: e1.id, parentId: e1.parentId  }))
								}
								temp.push({ dictValue: e.nodeCode, dictLabel: e.nodeName, id: e.id, parentId: e.parentId, children })
							})
						}
						this.filterCondition[0].actions.push(...temp)
					}
				} else {
					let obj = {
						Q_isProcess_EQ:'1',
						Q_isDel_EQ:'0',
					}
					let res = await api.getProjectNodeList(obj)
					if (res.code == 200) {
						let temp = []
						res.data.forEach(e => {
							temp.push({
								dictValue: e.nodeCode,
								dictLabel: e.nodeName
							})
						})
						this.filterCondition[0].actions.push(...temp)
					}
				}
			},
			//巡检详情
			toPatrolDetails(item) {
				sessionStorage.setItem('PatrolDetails',JSON.stringify(item))
				this.$router.push({
					name: "engineeringPatrolDetailslName",
				})
			}
		},
		mounted() {
			//获取巡检阶段列表
			this.getProjectInspectList()
		},
		created(){
			this.info = JSON.parse(sessionStorage.getItem('clientInfo'))
		},
	}
</script>

<style scoped lang="scss">
	.patrolList-style {
		.content-style {
			height: calc(100vh - 147px);
			overflow: auto;

			.list-item {
				text-align: left;

				.header-date {
					line-height: 21px;
					padding: 0 11px;
					font-size: 14px;
					font-weight: 500;
					color: #777777;
				}

				.center-content {
					padding: 18px 11px 23px;
					display: flex;
					background: #FFFFFF;

					.tx-img {
						width: 36px;
						height: 36px;
						margin-right: 12px;
					}

					.content-info {
						width: 100vw;
						position: relative;

						.info-time {
							position: absolute;
							font-size: 12px;
							font-family: Source Han Sans SC;
							font-weight: 400;
							color: #AAAAAA;
							right: 0;
						}

						.info-name {
							font-size: 16px;
							font-weight: 500;
							color: #333333;
						}

						.info-item {
							display: flex;
							font-size: 14px;
							// font-family: Source Han Sans SC;
							font-weight: 400;
							color: #777777;
							line-height: 21px;

							.info-title {}

							.info-text {
								max-width: 222px;
							}

							.info-text2 div {
								display: inline-block;
								color: #4F7AFD;
							}
						}
					}
				}
			}
		}
	}
</style>
