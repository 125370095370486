<template>
    <div class="message-box">
        <headerNav title="消息详情" />
        <div :class="noticeType == '1' ? 'center' : 'center centerS'">
            <div class="card title">
                <div class="text-code">{{ basicInfo.finaleContract }}</div>
                <div class="text-content">
                    {{ approvalContent.variables.businessName }}
                    <span v-if="!approvalContent.variables.cancel">
                        <van-tag v-if="noticeType == '1'" type="warning" plain>待审核</van-tag>
                        <van-tag v-if="noticeType == '2'" type="success" plain>已同意</van-tag>
                        <van-tag v-if="noticeType == '3'" type="danger" plain>已拒绝</van-tag>
                    </span>
                    <van-tag v-else color="#4A7DFF" plain>已撤销</van-tag>
                </div>
            </div>
            <div class="card">
                <div class="approval-top">
                    <div class="approval-content">审批内容</div>
                    <div class="approval-time">{{ approvalContent.createDate }}</div>
                </div>
                <div class="approval-center">
                    <div class="item">
                        <div class="item-title">申请人：</div>
                        <div class="item-text">{{ approvalContent.startTaskUserName }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">申请名称：</div>
                        <div class="item-text">{{ approvalContent.variables.businessName }}</div>
                    </div>
                    <!-- 优惠申请/设计费 -->
                    <div class="item" v-if="businessType == '2' || businessType == '12'">
                        <div class="item-title">优惠金额：</div>
                        <div class="item-text">{{ businessType == '2' ? approvalContent.variables.var1 :
                            approvalContent.variables.var3 }}</div>
                    </div>
                    <!-- 飞单 -->
                    <div class="item" v-if="businessType == '3' || businessType == '4'">
                        <div class="item-title">退款金额：</div>
                        <div class="item-text money">{{ approvalContent.variables.var1 }}</div>
                    </div>
                    <!-- 充值金额 -->
                    <div class="item" v-if="businessType == '5'">
                        <div class="item-title">充值金额：</div>
                        <div class="item-text">{{ approvalContent.variables.var1 }}</div>
                    </div>
                    <!-- 充值金额 -->
                    <div class="item" v-if="businessType == '1'">
                        <div class="item-title">款项金额：</div>
                        <div class="item-text">{{ approvalContent.variables.var1 }}</div>
                    </div>
                    <!-- 充值金额 -->
                    <div class="item" v-if="businessType == '6'">
                        <div class="item-title">商品名称：</div>
                        <div class="item-text">{{ approvalContent.variables.var1 }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">申请类别：</div>
                        <div class="item-text">{{ approvalContent.procDefName }}</div>
                    </div>
                    <div class="item" v-if="businessType == '1'">
                        <div class="item-title">款项类型：</div>
                        <div class="item-text">{{ approvalContent.variables.var1 }}</div>
                    </div>
                    <div class="item" v-if="businessType == '2'">
                        <div class="item-title">金额备注：</div>
                        <div class="item-text">{{ approvalContent.variables.var2 }}</div>
                    </div>
                    <div class="item" v-if="['39', '44'].indexOf(businessType) < 0">
                        <div class="item-title">申请理由：</div>
                        <div class="item-text"
                            v-if="businessType == '3' || businessType == '4' || businessType == '12'">
                            {{ businessType == '12' ? approvalContent.variables.var6 : approvalContent.variables.var2 }}
                        </div>
                        <div class="item-text" v-if="businessType == '0'">{{ approvalContent.variables.var1 }}</div>
                        <div class="item-text" v-if="businessType == '11'">{{ approvalContent.variables.var3 }}</div>
                        <div class="item-text" v-if="businessType == '19'">{{ approvalContent.variables.refundRemark }}
                        </div>
                        <div class="item-text" v-if="businessType == '40'">{{ approvalContent.variables.remark }}</div>
                    </div>
                </div>
            </div>
            <div class="card" v-if="businessType === '14'  && approvalContent.variables?.var15">
                <div class="data-top" @click="() => isShowData1 = !isShowData1">
                    <div class="data-content">工程环节</div>
                    <div class="data-icon">
                        <van-icon v-if="!isShowData1" size="14px" name="arrow-down" />
                        <van-icon v-else size="14px" name="arrow-up" />
                    </div>
                </div>
                <div class="data-bottom" v-show="isShowData1">
                    <el-table :data="tableInfo" style="width: 100%" row-key="id" @row-click="rowClick"	@expand-change="expandChange" ref="elTable">
                        <template slot="empty">
                            <div class="empty-no-data">
                                <img src="../../assets/task-management/no-data.png" alt="">
                                <div>暂无数据</div>
                            </div>
                        </template>
                        <el-table-column prop="nodeName" label="工程环节">
                            <template slot-scope="scope">
                                <span v-if="scope.row.parentId == 0">
                                    <img src="../../assets/m23.svg" alt="" style="transform: translateY(2px);">
                                    <span style="color: #FF8450;margin-left: 4px;font-weight: bolder;">{{ scope.row.nodeName }}</span>
                                </span>
                                <span v-else>
                                    <span>{{ scope.row.nodeName }}</span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="dayQty" label="工期（天）"></el-table-column>
                        <el-table-column prop="ratioQty" label="工期占比(%)">
                            <template slot-scope="scope">
                                {{ (scope.row.ratioQty * 1 || 0).toFixed(2), }}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <!-- 辅材信息 -->
            <div v-if="businessType == '11'" class="card">
                <div class="data-top">
                    <div class="data-content">辅材信息</div>
                </div>
                <div>
                    <div class="list-item" v-for="(item, index) in materialsList">
                        <van-image class="left" :src="item.imgUrl ? item.imgUrl : require('@/assets/imgNull.png')"
                            @click="photoPreview(item.imgUrl)" />
                        <div class="item-center">
                            <div class="item-top">
                                <div class="top-name">{{ item.itemName }}</div>
                                <div class="item-right">{{ item.itemQty }}</div>
                            </div>
                            <div class="item-bottom">
                                <div>{{ item.modelSpec }}</div>
                                <div class="item-right">{{ item.unitName }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" v-if="businessType != '5'">
                <div class="data-top" @click="() => isShowData = !isShowData">
                    <div class="data-content">基本资料</div>
                    <div class="data-icon">
                        <van-icon v-if="!isShowData" size="14px" name="arrow-down" />
                        <van-icon v-else size="14px" name="arrow-up" />
                    </div>
                </div>
                <div v-if="isShowData">
                    <!-- 充值申请 -->
                    <!-- <div v-if="businessType == '5'">
                        <div class="item">
                            <div class="item-title">账号ID：</div>
                            <div class="item-text">{{ basicInfo.consumerName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">账号名称：</div>
                            <div class="item-text">{{ basicInfo.finaleContract }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">渠道：</div>
                            <div class="item-text">{{ basicInfo.gradeCode }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">运营人：</div>
                            <div class="item-text">{{ basicInfo.createDate }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">账户累计消耗：</div>
                            <div class="item-text">{{ basicInfo.channelName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">账户累计充值：</div>
                            <div class="item-text">{{ basicInfo.areaQty }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">账户余额：</div>
                            <div class="item-text">{{ basicInfo.orderProcess }}</div>
                        </div>
                    </div> -->
                    <!-- 完工申请/障碍单审批 -->
                    <div class="content" v-if="businessType == '0' || businessType == '8'">
                        <div class="item">
                            <div class="item-title">客户姓名：</div>
                            <div class="item-text">{{ basicInfo.consumerName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">合同号：</div>
                            <div class="item-text">{{ basicInfo.finaleContract }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">渠道：</div>
                            <div class="item-text">{{ basicInfo.channelName }}</div>
                        </div>
                        <div class="item" v-if="businessType == '0'">
                            <div class="item-title">客户等级：</div>
                            <div class="item-text">{{ basicInfo.gradeCode }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">装修面积：</div>
                            <div class="item-text">{{ basicInfo.areaQty }}</div>
                        </div>
                        <div class="item" v-if="businessType == '8'">
                            <div class="item-title">区域：</div>
                            <div class="item-text">{{ basicInfo.cityCode }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">详细地址：</div>
                            <div class="item-text">{{ basicInfo.detailAddress }}</div>
                        </div>
                        <!-- <div class="item" v-if="businessType == '8'">
                            <div class="item-title">工期：</div>
                            <div class="item-text">{{ basicInfo.orderProcess }}</div>
                        </div>
                        <div class="item" v-if="businessType == '8'">
                            <div class="item-title">应完工日期：</div>
                            <div class="item-text">{{ basicInfo.collected }}</div>
                        </div> -->
                        <div class="item">
                            <div class="item-title">已交金额：</div>
                            <div class="item-text">{{ basicInfo.collected }}</div>
                        </div>
                    </div>
                    <!-- 工程 -->
                    <div v-else-if="businessType == '14'">
                        <div class="item">
                            <div class="item-title">客户姓名：</div>
                            <div class="item-text">{{ basicInfo.consumerName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">合同号：</div>
                            <div class="item-text">{{ basicInfo.finaleContract }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">装修面积：</div>
                            <div class="item-text">{{ basicInfo.areaQty }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">区域：</div>
                            <div class="item-text">{{ basicInfo.cityCode }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">详细地址：</div>
                            <div class="item-text">{{ basicInfo.detailAddress }}</div>
                        </div>
                    </div>
                    <!-- 自理销售退款 -->
                    <div class="content content19" v-else-if="businessType == '19'">
                        <div class="item">
                            <div class="item-title">客户姓名：</div>
                            <div class="item-text">{{ basicInfo.consumerName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">合同号：</div>
                            <div class="item-text">{{ basicInfo.maintainContract }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">联系方式：</div>
                            <div class="item-text">{{ basicInfo.phoneNo }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">装修地址：</div>
                            <div class="item-text">{{ basicInfo.detailAddress }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">规划设计师：</div>
                            <div class="item-text">{{ basicInfo.transferName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">预审员：</div>
                            <div class="item-text">{{ basicInfo.budgetName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">深化设计师：</div>
                            <div class="item-text">{{ basicInfo.deepenName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">下单员：</div>
                            <div class="item-text">{{ basicInfo.productName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">督导：</div>
                            <div class="item-text">{{ basicInfo.supervisorName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">项目经理：</div>
                            <div class="item-text">{{ basicInfo.projectName }}</div>
                        </div>
                    </div>
                    <!-- 工程单分配变更业务任务流程 -->
                    <div v-else-if="businessType == '39'">
                        <div class="item">
                            <div class="item-title">客户姓名：</div>
                            <div class="item-text">{{ approvalContent.variables.customerName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">合同号：</div>
                            <div class="item-text">{{ approvalContent.variables.contractNo }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">装修地址：</div>
                            <div class="item-text">{{ approvalContent.variables.detailAddress }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">面积：</div>
                            <div class="item-text">{{ approvalContent.variables.areaAty }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">订单类型：</div>
                            <div class="item-text">{{ approvalContent.variables.orderPriceType == '1' ? '套餐' : '半包' }}
                            </div>
                        </div>
                    </div>
                    <div v-else-if="businessType == '40'">
                        <div class="item">
                            <div class="item-title">客户姓名：</div>
                            <div class="item-text">{{ approvalContent.variables.customerName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">合同号：</div>
                            <div class="item-text">{{ approvalContent.variables.contractNo }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">装修地址：</div>
                            <div class="item-text">{{ approvalContent.variables.detailAddress }}</div>
                        </div>
                    </div>
                    <div v-else-if="businessType == '41'">
                        <div class="item">
                            <div class="item-title">客户姓名：</div>
                            <div class="item-text">{{ approvalContent.variables.customerName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">合同号：</div>
                            <div class="item-text">{{ approvalContent.variables.contractNo }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">装修地址：</div>
                            <div class="item-text">{{ approvalContent.variables.detailAddress }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title" style="width: 125px;">预计开工日期：</div>
                            <div class="item-text">{{ approvalContent.variables.planStartDate }}</div>
                        </div>
                    </div>
                    <div v-else-if="businessType == '42'">
                        <div class="item">
                            <div class="item-title">客户姓名：</div>
                            <div class="item-text">{{ approvalContent.variables.customerName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">合同号：</div>
                            <div class="item-text">{{ approvalContent.variables.contractNo }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">装修地址：</div>
                            <div class="item-text">{{ approvalContent.variables.detailAddress }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">面积：</div>
                            <div class="item-text">{{ approvalContent.variables.areaAty }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">订单类型：</div>
                            <div class="item-text">{{ approvalContent.variables.orderPriceType == '1' ? '套餐' : '半包' }}
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-title">工程计划：</div>
                            <div class="item-text">{{ approvalContent.variables.planName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">工期：</div>
                            <div class="item-text">{{ approvalContent.variables.durationDays }}</div>
                        </div>
                    </div>
                    <div v-else-if="businessType == '43'">
                        <div class="item">
                            <div class="item-title">客户姓名：</div>
                            <div class="item-text">{{ approvalContent.variables.customerName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">合同号：</div>
                            <div class="item-text">{{ approvalContent.variables.contractNo }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">装修地址：</div>
                            <div class="item-text">{{ approvalContent.variables.detailAddress }}</div>
                        </div>
                    </div>
                    <div v-else-if="businessType == '44'">
                        <div class="item">
                            <div class="item-title">客户姓名：</div>
                            <div class="item-text">{{ approvalContent.variables.customerName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">合同号：</div>
                            <div class="item-text">{{ approvalContent.variables.contractNo }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">装修地址：</div>
                            <div class="item-text">{{ approvalContent.variables.detailAddress }}</div>
                        </div>
                    </div>
                    <div v-else-if="businessType == '45'">
                        <div class="item">
                            <div class="item-title">客户姓名：</div>
                            <div class="item-text">{{ approvalContent.variables.customerName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">合同号：</div>
                            <div class="item-text">{{ approvalContent.variables.contractNo }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">装修地址：</div>
                            <div class="item-text">{{ approvalContent.variables.detailAddress }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">应完工日期：</div>
                            <div class="item-text">{{ approvalContent.variables.targetFinishDate }}</div>
                        </div>
                    </div>
                    <div v-else-if="businessType == '46'">
                        <div class="item">
                            <div class="item-title">客户姓名：</div>
                            <div class="item-text">{{ approvalContent.variables.customerName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">合同号：</div>
                            <div class="item-text">{{ approvalContent.variables.contractNo }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">装修地址：</div>
                            <div class="item-text">{{ approvalContent.variables.detailAddress }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">应竣工日期：</div>
                            <div class="item-text">{{ approvalContent.variables.targetCompletedDate }}</div>
                        </div>
                    </div>
                    <div v-else-if="businessType == '47'">
                        <div class="item">
                            <div class="item-title">客户姓名：</div>
                            <div class="item-text">{{ approvalContent.variables.customerName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">合同号：</div>
                            <div class="item-text">{{ approvalContent.variables.contractNo }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">装修地址：</div>
                            <div class="item-text">{{ approvalContent.variables.detailAddress }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">应交付日期：</div>
                            <div class="item-text">{{ approvalContent.variables.targetTransferDate }}</div>
                        </div>
                    </div>
                    <div v-else-if="businessType == '48'">
                        <div class="item">
                            <div class="item-title">客户姓名：</div>
                            <div class="item-text">{{ approvalContent.variables.customerName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">合同号：</div>
                            <div class="item-text">{{ approvalContent.variables.contractNo }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">装修地址：</div>
                            <div class="item-text">{{ approvalContent.variables.detailAddress }}</div>
                        </div>
                    </div>
                    <div v-else-if="businessType == '49' || businessType == '52'">
                        <div class="item">
                            <div class="item-title">客户姓名：</div>
                            <div class="item-text">{{ approvalContent.variables.customerName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">合同号：</div>
                            <div class="item-text">{{ approvalContent.variables.contractNo }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">装修地址：</div>
                            <div class="item-text">{{ approvalContent.variables.detailAddress }}</div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="item">
                            <div class="item-title">客户姓名：</div>
                            <div class="item-text">{{ basicInfo.consumerName }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">合同号：</div>
                            <div class="item-text">{{ basicInfo.finaleContract }}</div>
                        </div>
                        <!-- 不是收款计划或者解锁申请时展示 -->
                        <div class="item" v-if="businessType != '1' && businessType != '7' && businessType != '11'">
                            <div class="item-title">客户等级：</div>
                            <div class="item-text">{{ basicInfo.gradeCode }}</div>
                        </div>
                        <div class="item" v-if="businessType != '11'">
                            <div class="item-title">签单时间：</div>
                            <div class="item-text">{{ basicInfo.createDate }}</div>
                        </div>
                        <div class="item" v-if="businessType != '11'">
                            <div class="item-title">渠道：</div>
                            <div class="item-text">{{ basicInfo.channelName }}</div>
                        </div>
                        <div class="item" v-if="businessType != '11'">
                            <div class="item-title">装修面积：</div>
                            <div class="item-text">{{ basicInfo.areaQty }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">区域：</div>
                            <div class="item-text">{{ basicInfo.cityCode }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">详细地址：</div>
                            <div class="item-text">{{ basicInfo.detailAddress }}</div>
                        </div>
                        <div class="item" v-if="businessType != '11'">
                            <div class="item-title">订单进度：</div>
                            <div class="item-text">{{ basicInfo.orderProcess }}</div>
                        </div>
                        <div class="item" v-if="businessType != '11'">
                            <div class="item-title">已交金额：</div>
                            <div class="item-text">{{ basicInfo.collected }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" v-if="businessType == '39'">
                <div class="data-top">
                    <div class="data-content">分配信息</div>
                </div>
                <div>
                    <div class="item">
                        <div class="item-title">分配人：</div>
                        <div class="item-text">{{ approvalContent.variables.assignerName }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">分配原因：</div>
                        <div class="item-text">{{ approvalContent.variables.assignableCause }}</div>
                    </div>
                </div>
            </div>
            <div class="card" v-if="businessType == '41'">
                <div class="data-top">
                    <div class="data-content">延期开工信息</div>
                </div>
                <div>
                    <div class="item">
                        <div class="item-title" style="width:125px">延期开工天数：</div>
                        <div class="item-text">{{ approvalContent.variables.delayDay }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">备注：</div>
                        <div class="item-text">{{ approvalContent.variables.remark }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">图片：</div>
                        <!-- <div class="item-text">{{ approvalContent.variables.assignableCause }}</div> -->
                        <div>
                            <van-image class="item-img"
                                v-for="(imgItem, imgIndex) in (approvalContent.variables.picInfo?.split(',') || [])"
                                :src="imgItem"
                                @click="photoPreview((approvalContent.variables.picInfo?.split(',') || []), imgIndex)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" v-if="businessType == '42'">
                <div class="data-top">
                    <div class="data-content">开工信息</div>
                </div>
                <div>
                    <div class="item">
                        <div class="item-title">开工时间：</div>
                        <div class="item-text">{{ approvalContent.variables.startDate }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">实际工期：</div>
                        <div class="item-text">{{ approvalContent.variables.durationDays }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">备注：</div>
                        <div class="item-text">{{ approvalContent.variables.remark }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">图片：</div>
                        <!-- <div class="item-text">{{ approvalContent.variables.assignableCause }}</div> -->
                        <div>
                            <van-image class="item-img"
                                v-for="(imgItem, imgIndex) in (approvalContent.variables.picInfo?.split(',') || [])"
                                :src="imgItem"
                                @click="photoPreview((approvalContent.variables.picInfo?.split(',') || []), imgIndex)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" v-if="businessType == '43'">
                <div class="data-top">
                    <div class="data-content">开工信息</div>
                </div>
                <div>
                    <div class="item">
                        <div class="item-title">开工天数：</div>
                        <div class="item-text">{{ approvalContent.variables.startDay }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">备注：</div>
                        <div class="item-text">{{ approvalContent.variables.remark }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">图片：</div>
                        <!-- <div class="item-text">{{ approvalContent.variables.assignableCause }}</div> -->
                        <div>
                            <van-image class="item-img"
                                v-for="(imgItem, imgIndex) in (approvalContent.variables.picInfo?.split(',') || [])"
                                :src="imgItem"
                                @click="photoPreview((approvalContent.variables.picInfo?.split(',') || []), imgIndex)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" v-if="businessType == '44'">
                <div class="data-top">
                    <div class="data-content">退回信息</div>
                </div>
                <div>
                    <div class="item">
                        <div class="item-title">退回原因：</div>
                        <div class="item-text">{{ approvalContent.variables.remark }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">图片：</div>
                        <!-- <div class="item-text">{{ approvalContent.variables.assignableCause }}</div> -->
                        <div>
                            <van-image class="item-img"
                                v-for="(imgItem, imgIndex) in (approvalContent.variables.picInfo?.split(',') || [])"
                                :src="imgItem"
                                @click="photoPreview((approvalContent.variables.picInfo?.split(',') || []), imgIndex)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" v-if="businessType == '45'">
                <div class="data-top">
                    <div class="data-content">完工信息</div>
                </div>
                <div>
                    <div class="item">
                        <div class="item-title">完工日期：</div>
                        <div class="item-text">{{ approvalContent.variables.completionDate }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">完工备注：</div>
                        <div class="item-text">{{ approvalContent.variables.remark }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">完工单图片：</div>
                        <!-- <div class="item-text">{{ approvalContent.variables.assignableCause }}</div> -->
                        <div>
                            <van-image class="item-img"
                                v-for="(imgItem, imgIndex) in (JSON.parse(approvalContent.variables.picInfo) || [])"
                                :src="imgItem.fileUrl"
                                @click="photoPreview((JSON.parse(approvalContent.variables.picInfo) || []), imgIndex)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" v-if="businessType == '46'">
                <div class="data-top">
                    <div class="data-content">俊工信息</div>
                </div>
                <div>
                    <div class="item">
                        <div class="item-title">俊工日期：</div>
                        <div class="item-text">{{ approvalContent.variables.completionDate }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">俊工备注：</div>
                        <div class="item-text">{{ approvalContent.variables.remark }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">竣工单图片：</div>
                        <!-- <div class="item-text">{{ approvalContent.variables.assignableCause }}</div> -->
                        <div>
                            <van-image class="item-img"
                                v-for="(imgItem, imgIndex) in (JSON.parse(approvalContent.variables.picInfo) || [])"
                                :src="imgItem.fileUrl"
                                @click="photoPreview((JSON.parse(approvalContent.variables.picInfo) || []), imgIndex)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" v-if="businessType == '47'">
                <div class="data-top">
                    <div class="data-content">结转信息</div>
                </div>
                <div>
                    <div class="item">
                        <div class="item-title">完成日期：</div>
                        <div class="item-text">{{ approvalContent.variables.completionDate }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">备注：</div>
                        <div class="item-text">{{ approvalContent.variables.remark }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">图片：</div>
                        <!-- <div class="item-text">{{ approvalContent.variables.assignableCause }}</div> -->
                        <div>
                            <van-image class="item-img"
                                v-for="(imgItem, imgIndex) in (JSON.parse(approvalContent.variables.picInfo) || [])"
                                :src="imgItem.fileUrl"
                                @click="photoPreview((JSON.parse(approvalContent.variables.picInfo) || []), imgIndex)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" v-if="businessType == '48'">
                <div class="data-top">
                    <div class="data-content">延期信息</div>
                </div>
                <div>
                    <div class="item">
                        <div class="item-title">延期工序：</div>
                        <div class="item-text">{{ approvalContent.variables.processName }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">延期天数：</div>
                        <div class="item-text">{{ approvalContent.variables.adaptDay }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">累计延期天数：</div>
                        <div class="item-text">{{ approvalContent.variables.sumAdaptDay }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">备注：</div>
                        <div class="item-text">{{ approvalContent.variables.remark }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">图片：</div>
                        <!-- <div class="item-text">{{ approvalContent.variables.assignableCause }}</div> -->
                        <div>
                            <van-image class="item-img"
                                v-for="(imgItem, imgIndex) in (approvalContent.variables.fileUrls?(approvalContent.variables.fileUrls?.split(',')) : [])"
                                :src="imgItem"
                                @click="photoPreview((approvalContent.variables.fileUrls?(approvalContent.variables.fileUrls?.split(',')) : []), imgIndex)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" v-if="businessType == '49'">
                <div class="data-top">
                    <div class="data-content">延期信息</div>
                </div>
                <div>
                    <div class="item">
                        <div class="item-title">缩减工序：</div>
                        <div class="item-text">{{ approvalContent.variables.processName }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">缩减天数：</div>
                        <div class="item-text">{{ approvalContent.variables.adaptDay }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">累计缩减天数：</div>
                        <div class="item-text">{{ approvalContent.variables.sumAdaptDay }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">备注：</div>
                        <div class="item-text">{{ approvalContent.variables.remark }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">图片：</div>
                        <!-- <div class="item-text">{{ approvalContent.variables.assignableCause }}</div> -->
                        <div>
                            <van-image class="item-img"
                                v-for="(imgItem, imgIndex) in (approvalContent.variables.fileUrls?(approvalContent.variables.fileUrls?.split(',')) : [])"
                                :src="imgItem"
                                @click="photoPreview((approvalContent.variables.fileUrls?(approvalContent.variables.fileUrls?.split(',')) : []), imgIndex)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" v-if="businessType == '52'">
                <div class="data-top">
                    <div class="data-content">动工信息</div>
                </div>
                <div>
                    <div class="item">
                        <div class="item-title">开工日期：</div>
                        <div class="item-text">{{ approvalContent.variables.startDate }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">动工日期：</div>
                        <div class="item-text">{{ approvalContent.variables.actualStartDate }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">实际日期：</div>
                        <div class="item-text">{{ approvalContent.variables.actualDatePeriods }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">调整原因：</div>
                        <div class="item-text">{{ approvalContent.variables.remark }}</div>
                    </div>
                    <div class="item">
                        <div class="item-title">图片：</div>
                        <!-- <div class="item-text">{{ approvalContent.variables.assignableCause }}</div> -->
                        <div>
                            <div>
                                <van-image class="item-img"
                                    v-for="(imgItem, imgIndex) in (approvalContent.variables.picInfo?.split(',') || [])"
                                    :src="imgItem"
                                    @click="photoPreview((approvalContent.variables.picInfo?.split(',') || []), imgIndex)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 自理销售退款 -->
            <div class="card" v-if="businessType == '19'">
                <div class="data-top" @click="() => isShowData19 = !isShowData19">
                    <div class="data-content">退款信息</div>
                    <div class="data-icon">
                        <van-icon v-if="!isShowData19" size="14px" name="arrow-down" />
                        <van-icon v-else size="14px" name="arrow-up" />
                    </div>
                </div>
                <div v-if="isShowData19">
                    <div class="content content19-">
                        <div class="item">
                            <div class="item-title">合同金额：</div>
                            <div class="item-text">{{ (basicInfo.contractSum || 0).toFixed(2) }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">变更金额：</div>
                            <div class="item-text">{{ (basicInfo.changeSum || 0).toFixed(2) }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">责任人承担金额：</div>
                            <div class="item-text">{{ (basicInfo.adjectiveSum || 0).toFixed(2) }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">已缴金额：</div>
                            <div class="item-text">{{ (basicInfo.paidSum || 0).toFixed(2) }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">溢缴金额：</div>
                            <div class="item-text">{{ (basicInfo.overpaySum || 0).toFixed(2) }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">本次退款金额：</div>
                            <div class="item-text">{{ (basicInfo.refundSum || 0).toFixed(2) }}</div>
                        </div>
                        <div class="item">
                            <div class="item-title">退款原因：</div>
                            <div class="item-text">{{ basicInfo.refundRemark }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="approval-top">
                    <div class="approval-content">审批进度</div>
                </div>
                <div v-if="approvalContent.approvalInfoDTO">
                    <van-steps direction="vertical" :active="approvalContent.approvalInfoDTO.length"
                        active-color="#0066FF" class="steps" active-icon="success">
                        <van-step v-for="(item, index) in approvalContent.approvalInfoDTO" :key="index">
                            <div class="step-name">
                                {{ item.approvalPersonName }}
                                <van-tag v-if="item.isAgree == 'yes'" type="success" plain>已同意</van-tag>
                                <van-tag v-if="item.isAgree == 'no'" type="danger" plain>已拒绝</van-tag>
                            </div>
                            <div class="step-content">{{ item.opinion }}</div>
                            <div class="step-time">{{ item.approvalTime }}</div>
                        </van-step>
                    </van-steps>

                </div>
            </div>
        </div>
        <div class="footer" v-if="noticeType == '1'">
            <div :class="approvalContent.variables.cancel ? 'refuseE' : 'refuse'" @click="handApproval(false)">拒绝</div>
            <div :class="approvalContent.variables.cancel ? 'refuseE' : 'agree'" @click="handApproval(true)">同意</div>
        </div>
        <van-dialog v-model="approvalDialog" show-cancel-button :before-close="dialogSubmit"
            confirmButtonColor="#1989fa">
            <template #title>
                <div class="dialog-title">审批意见<span style="color: red;">*</span></div>
            </template>
            <div style="padding: 10px 20px;">
                <van-field v-model="approvalOpinion" rows="2" autosize type="textarea" required :maxlength="maxlength"
                    placeholder="​请输入审批意见" show-word-limit />
            </div>
        </van-dialog>
    </div>
</template>

<script>
import api from '../../api/api.js'
import {
    Toast, ImagePreview
} from 'vant';
export default {
    data () {
        return {
            isShowData: false,
            isShowData1: false,
            isShowData19: false,
            approvalDialog: false,
            approvalOpinion: '',//审批意见
            businessType: '',//单据类型
            noticeType: '',//通知类型0审批通知 1审批通过 2审批拒绝
            bussinessId: '',
            procInstanceId: '',
            basicInfo: {},//基本信息
            approvalContent: {
                variables: {}
            },//审批内容
            materialsList: [],//材料列表
            approvalResult: '',//审批结果
            approverUserInfo: {},//下一步审批人信息
            messageId: '',//消息id
            tableInfo: [],//工程环节列表
            expandList: [],//折叠
            maxlength: 120,
        }
    },
    methods: {
        //审批
        handApproval (v) {
            if (!this.approvalContent.variables.cancel) {
                if (this.businessType == '19') {
                    this.maxlength = 50
                }
                this.approvalOpinion = ''
                this.approvalDialog = true
                this.approvalResult = v ? "yes" : "no"
            }
        },
        //审批提交
        async dialogSubmit (action, done) {
            if (action === 'confirm') { // 确认
                if (!this.approvalOpinion.trim().length) {
                    Toast.fail('请输入审批意见')
                    done(false)
                    return
                }
                let obj = {
                    procInstId: this.approvalContent.procInstId,//流程实例id
                    taskId: this.approvalContent.taskId,//当前任务id
                    taskName: this.approvalContent.taskName,
                    variables: {
                        is: this.approvalResult,//no拒绝，yes 同意
                        reason: this.approvalOpinion,
                        history: this.approvalContent.variables.history,
                        businessId: this.approvalContent.variables.businessId,
                        pass: this.approvalContent.variables.pass,
                    },
                    startTaskUserId: this.approvalContent.startTaskUserId,
                    startTaskUserName: this.approvalContent.startTaskUserName,
                    businessKey: this.approvalContent.businessKey,
                }
                //不是辅材的情况
                if (this.businessType != '11') {
                    obj = {
                        procInstId: this.approvalContent.procInstId,//流程实例id
                        taskId: this.approvalContent.taskId,//当前任务id
                        taskName: this.approvalContent.taskName,
                        variables: {
                            ...this.approvalContent?.variables,
                            is: this.approvalResult,//拒绝，yes 同意
                            reason: this.approvalOpinion,
                            history: this.approvalContent.variables.history,
                            businessId: this.approvalContent.variables.businessId,
                            pass: this.approvalContent.variables.pass,
                        },
                        defineUserId: this.approverUserInfo.accountId ? this.approverUserInfo.accountId[0] : undefined,
                        defineGroupIdList: this.approverUserInfo.groupIds ? this.approverUserInfo.groupIds : undefined,
                        updateUserId: this.approverUserInfo.accountId ? this.approverUserInfo.accountId[0] : undefined,
                        updateGroupIdList: this.approverUserInfo.groupIds && this.businessType != '2' ? this.approverUserInfo.groupIds : undefined,
                        accountIdList: this.approverUserInfo.accountIdList ? this.approverUserInfo.accountIdList : undefined,
                        updateAccountIdList: undefined,
                        startTaskUserId: this.approvalContent.startTaskUserId,
                        startTaskUserName: this.approvalContent.startTaskUserName,
                        businessKey: this.approvalContent.businessKey,
                    }
                }
                try {
                    let res = await api.approvalSubmit(obj)
                    if (res.code == 200) {
                        if (!res.success) {
                            Toast.fail(res.msg)
                        } else {
                            Toast.success('操作成功！')
                        }
                        this.getApprovalContent()
                        done()
                    }
                } catch (error) {
                    done()
                    this.getApprovalContent()
                }
            } else if (action === 'cancel') { // 取消
                done(); // 关闭提示框
            }
        },
        //获取审批详情
        async getApprovalDetails () {
            //充值申请不调用获取基本资料方法
            let filterArr = ['5', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49','52']
            if (filterArr.indexOf(this.businessType)>-1) return
            if (this.businessType == '19') {
                let res = await api.approvalDetails3(this.approvalContent.variables)
                if (res.code == 200) {
                    this.basicInfo = res.data
                }
                return
            }
            let res = await api.approvalDetails(this.bussinessId)
            if (res.code == 200) {
                this.basicInfo = res.data
            }
        },
        //获取审批内容
        async getApprovalContent () {
            let obj = {
                noticeType: this.$route.query.approvalNoticeType,
                procInstanceId: this.procInstanceId,
                msgId: this.messageId
            }
            let res = await api.approvalContent(obj)
            if (res.code == 200) {
                this.noticeType = res.data.noticeType
                if (res.data.taskDTO) this.approvalContent = res.data.taskDTO
                if(res.data?.taskDTO?.variables?.businessType == '14' && res.data?.taskDTO?.variables?.var15){
                    this.getProjectInfoNodeInfo()
                }
            }
        },
        //获取工程计划环节
        async getProjectInfoNodeInfo () {
            let obj = {
                id: this.approvalContent.variables.var15
            }
            let res = await api.projectInfoNodeInfoById(obj)
            if (res.code == 200) {
                this.tableInfo = res.data.nodeList || []
            }
        },
        rowClick(row) {
            if (row.parentId == '0' && row.children?.length) {
                const flag = this.expandList.includes(row.id)
				if (flag) {
                    this.$refs.elTable.toggleRowExpansion(row, false);
					this.expandList = this.expandList.filter(item => item !== row.id)
				} else {
					this.$refs.elTable.toggleRowExpansion(row, true);
					this.expandList.push(row.id)
				}
			}
		},
		expandChange(row, expanded) {
			if (expanded) {
				this.expandList = Array.from(new Set([...this.expandList, row.id]))
			} else {
				this.expandList = this.expandList.filter(item => item !== row.id)
			}
		},
        //获取辅材详情
        async getMaterialsDetails () {
            let obj = {
                Q_applyId_EQ: this.approvalContent.variables.applyId
            }
            let res = await api.getMaterialsDetails(obj)
            if (res.code == 200) {
                this.materialsList = res.data
            }
        },
        //图片预览
        photoPreview (url, index) {
            if (!url) return
            ImagePreview({
                images: [url],
                startPosition: index || 0,
            });
        },
        //查询下一步审批人员
        async checkPeo () {
            let res = await api.checkPeo({ processInstanceId: this.procInstanceId, stepType: 'now', weChatFlag: '1' })
            if (res.code == 200) {
                this.approverUserInfo = res.data
            }
        }
    },
    async created () {
        this.procInstanceId = this.$route.query.procInstanceId
        this.businessType = this.$route.query.businessType
        this.bussinessId = this.businessType == '11' ? this.$route.query.orderId : this.$route.query.bussinessId
        this.messageId = this.$route.query.messageId
        this.checkPeo()
        await this.getApprovalContent()
        await this.getApprovalDetails()
        if (this.businessType == '11') {
            await this.getMaterialsDetails()
        }
    }
}
</script>

<style scoped lang="scss">
.message-box {
    text-align: left;

    .center {
        height: calc(100vh - 114px);
        background: #F5F5F5;
        padding-top: 0.1px;
        overflow: auto;

        .card {
            background: #FFFFFF;
            margin: 16px 0;
            padding-bottom: 0.1px;

        }

        .title {
            height: 64px;
            background: #FFFFFF;
            padding: 4px 16px;
            box-sizing: border-box;
            margin: 8px 0;

            .text-code {
                font-size: 10px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #999999;
                line-height: 14px;
                margin-bottom: 4px;
            }

            .text-content {
                font-size: 14px;
                font-family: PingFang SC-Bold, PingFang SC;
                font-weight: bold;
                color: #333333;
                line-height: 20px;
            }
        }

        .approval-top,
        .data-top {
            display: flex;
            height: 47px;
            border-bottom: 1px solid #F5F5F5;

            div {
                flex: 1;
                display: flex;
                align-items: center;
                padding: 0 16px;
            }

            .approval-content,
            .data-content {
                font-size: 14px;
                font-family: PingFang SC-Bold, PingFang SC;
                font-weight: bold;
                color: #333333;
            }

            .approval-time,
            .data-icon {
                font-size: 10px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #999999;
                justify-content: end;
            }
        }

        .item {
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            line-height: 20px;
            display: flex;
            padding: 0 16px;
            margin: 16px 0;

            .item-title {
                width: 70px;
                color: #666666;
            }

            .item-text {
                color: #333333;
                width: calc(100% - 70px);
                word-wrap: break-word;
                word-break: break-all;
            }

            div:has(.item-img) {
                width: calc(100% - 100px);
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
            }

            .item-img {
                width: 50px;
                height: 50px;
            }
        }

        .content19 .item-title {
            width: 100px;
        }

        .content19- .item-title {
            width: 136px;
        }

        .money {
            font-weight: bold;
            color: #FF8450 !important;
        }

        .steps {
            width: 65%;
            margin-left: 25%;
        }

        .step-name,
        .step-content {
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 16px;
            margin-bottom: 8px;
            word-wrap: break-word;
            word-break: break-all;
        }

        .step-time {
            width: 80px;
            text-align: center;
            position: absolute;
            top: 0;
            left: -14vh;
            left: -110px;
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #666666;
        }
    }

    .centerS {
        height: calc(100vh - 50px);
    }

    .footer {
        z-index: 999;
        position: fixed;
        width: 100%;
        bottom: 0;
        display: flex;
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #FFFFFF;

        div {
            flex: 1;
            text-align: center;
            line-height: 64px;
        }

        .refuse {
            background: #FF8450;
        }

        .refuseE {
            background: #CECECE;
        }

        .agree {
            background: #38B865;
        }
    }

    .list-item {
        border-bottom: 1px solid #E6E6E6;
        height: 64px;
        display: flex;
        padding: 8px 16px;
        align-items: center;
        box-sizing: border-box;

        .left {
            width: 48px;
            height: 48px;
        }

        .item-center {
            width: calc(100% - 50px);

            .item-bottom,
            .item-top {
                display: flex;
                margin: 0 8px;

                div {
                    flex: 1;
                }

                .item-right {
                    text-align: right;
                }
            }

            .item-top {
                margin-bottom: 4px;
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;

                .top-name {
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden; //溢出内容隐藏
                    text-overflow: ellipsis; //文本溢出部分用省略号表示
                    display: -webkit-box; //特别显示模式
                    -webkit-line-clamp: 2; //行数
                    line-clamp: 2;
                    -webkit-box-orient: vertical; //盒子中内容竖直排列
                }
            }

            .item-bottom {
                margin-bottom: 4px;
                font-size: 12px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #999999;
            }
        }

    }
}
</style>
<style lang="scss">
.message-box {
    .van-step__circle-container {
        width: 14px;
        height: 14px;
        box-sizing: border-box;
        background: #0066FF;
        border: 3px solid #CCDDFF;
        border-radius: 50%;

        .van-step__circle {
            display: none;
        }
    }

    .van-field {
        background: #f2f3f5;
    }
}

.van-step__title {
    position: relative;
}

.van-steps {
    overflow: inherit;
}
</style>