<template>
    <div class="construction-style">
        <div class="search-style">
            <van-field class="choosetime-input" v-model="searchValue" @blur="onRefresh()" @clear="onClear" clearable
                placeholder="请输入客户姓名/电话/合同号/装修地址" center>
                <template v-slot:right-icon>
                    <div style="height: 100%;display: flex;align-items: center;">
                        <img src="../../../assets/search.png" class="search-icon" alt="">
                    </div>
                </template>
            </van-field>
        </div>
        <myFilterModel :filterList="filterCondition" @filterFunction="onRefresh"></myFilterModel>
        <div class="content-style">
            <pullRefresh ref="pullRefresh" @refresh="onRefresh">
                <van-list class="vant-list-style" v-model="loading" :finished="finished"
                    :finished-text="total ? '·已加载全部数据·' : '·暂无数据·'" @load="onLoad">
                    <div class="item-style" v-for="(item, index) in list" :key="index">
                        <div class="top" @click="toClientDetail(item)">
                            <img src="../../../assets/tx.png" alt="" class="tx-img">
                            <div class="top-right">
                                <div class="name-style">{{ item.consumerName }}</div>
                                <div class="linkPhone-style">电话：{{ item.phoneHypNo }}</div>
                            </div>
                            <img src="../../../assets/xzjt.png" alt="" class="jt-img">
                        </div>
                        <div class="center">
                            <div class="center-top">
                                <div>施工合同：{{ item.finaleContract }}</div>
                                <div v-if="item.startDate">{{ item.startDate.slice(0, 10) }}开工</div>
                                <div v-if="item.auditStatus == 4">工期{{ item.dateLimit }}天</div>
                            </div>
                            <div class="title">装修地址：<span class="text"> {{ item.detailAddress }}</span></div>
                            <div class="title">
                                施工状态：
                                <span class="text">【{{ item.statusCode }}】</span>
                            </div>
                            <div class="title">施工进度：<span class="text"> {{ item.nodeName }}</span></div>
                            <div class="title">项目经理：<span class="text"> {{ item.projectName }}</span></div>
                        </div>
                        <div class="bottom">
                            <div class="bottom-item-style" @click="callPhone(item.phoneNo)">
                                <span class="text">拨打电话</span>
                            </div>
                            <div class="bottom-item-style" @click="grade(item, index)"
                                v-if="permission('mobile:project:grade')">
                                <span v-if="item.scoreValue || item.scoreValue === 0" class="text2">{{ item.scoreValue
                                }}分</span>
                                <span v-else class="text">工地评分</span>

                            </div>
                            <div class="bottom-item-style" @click="toProjectPlan(item)">
                                <span :class="['text', item.statusCode === '未开工' && 'text1']">工程进度</span>
                            </div>
                            <div class="bottom-item-style" style="border: none;" @click="toPatrol(item)">
                                <span class="text">巡检记录</span>
                            </div>
                        </div>
                    </div>
                </van-list>
            </pullRefresh>
        </div>
        <!-- <van-action-sheet v-model="isShowGrade" :actions="gradeActions" cancel-text="取消" close-on-click-action
			@cancel="isShowGrade = false" @select="onSelectGrade" /> -->

        <van-popup v-model="isShowGrade" :round="false" position="bottom" :lazy-render="false">
            <div class="sheet-top">
                <div class="sheet-cancel" @click="isShowGrade = false">取消</div>
                <div class="sheet-sure" @click="gradeSure">确定</div>
            </div>
            <van-picker ref="gradePicker" :columns="gradeActions" />
        </van-popup>
    </div>
</template>

<script>
import api from '@/api/api.js'
import myFilterModel from '@/components/filterModel1.vue'
import { timeFormat } from '@/utils/timeCompute'
export default {
    data() {
        return {
            searchValue: '',
            list: [],
            action: {},
            actions: [],
            filterTypeId: '',
            filterCondition: [
                {
                    label: '施工状态',
                    defaultLabel: '施工状态',
                    field: 'statusCode',
                    type: "select",
                    activeValue: '00',
                    actions: [
                        {
                            value: '00',
                            text: '所有状态'
                        },
                        // {
                        // 	value: '1',
                        // 	text: '未开工'
                        // },
                        // {
                        // 	value: '2',
                        // 	text: '施工中'
                        // },
                        // {
                        // 	value: '3',
                        // 	text: '已完工'
                        // },
                    ]
                },
                {
                    label: '施工进度',
                    defaultLabel: '施工进度',
                    field: 'processCode',
                    type: 'Cascader',
					selectTitle: '选择施工进度',
                    activeValue: '00',
                    actions: [{
                        dictValue: '00',
                        dictLabel: '所有进度'
                    },]
                },
                {
                    label: ['开工日期'],
                    defaultLabel: ['开工日期'],
                    field: 'date-quantum',
                    defaultLabel: ['开工日期'],
                    activeValue: '00',
                    type: 'timeQuantum',
                },
                {
                    label: '项目经理',
                    defaultLabel: '项目经理',
                    field: 'projectId',
                    type: "inputSelect",
                    activeValue: '00',
                    actions: []
                },
            ],
            isShowGrade: false,
            gradeActions: [],
            clientDetails: {},
            clientDetailsIndex: 0,
            sheetShow: false,
            isLoading: false,
            loading: false,
            finished: false,
            error: false, // 是否加载失败
            pageNum: 1, // 分页
            pageSize: 10, // 每页条数
            total: 0 // 数据总条数
        }
    },
    components: {
        myFilterModel
    },
    created() {
        for (let i = 0; i < 101; i++) {
            this.gradeActions.unshift(i)
        }
    },
    methods: {
        //返回首页
        back() {
            this.$router.push({ name: 'indexName' })
        },
        onClear() {
            this.searchValue = ""
        },
        //获取列表
        async getList() {
            try {
                const [statusCode, processCode, time, projectId] = this.filterCondition
                const obj = {
                    page: this.pageNum,
                    limit: this.pageSize,
                    time: time.activeValue === "00" ? '' : [time.activeValue[0] + ' 00:00:00', time.activeValue[1] + ' 23:59:59'].join('~'),
                    statusCode: statusCode.activeValue === "00" ? '' : statusCode.activeValue,
                    processCode: processCode.activeValue === "00" ? '' : processCode.activeValue,
                    projectId: (projectId?.activeValue ?? '00') === "00" ? '' : projectId.activeValue,
                    content: this.searchValue,
                }
                let res = await api.getPatrolList(obj)
                if (res.code == 200) {
                    this.total = res.count;
                    if (res.data.length === 0) { // 判断获取数据条数若等于0
                        this.list = []; // 清空数组
                        this.finished = true; // 停止加载
                    } else {
                        // 若数据条数不等于0
                        this.list.push(...res.data) // 将数据放入list中
                        this.loading = false; // 加载状态结束 
                        // 如果list长度大于等于总数据条数,数据全部加载完成
                        if (this.list.length >= res.count) {
                            this.finished = true; // 结束加载状态
                        }
                    }
                    this.isLoading = false
                    if (this.$refs.pullRefresh) {
                        this.$refs.pullRefresh.refreshSuccess()
                    }
                }
            } catch (error) {
                this.isLoading = false
                this.loading = false
                this.finished = true
                this.error = false
            }
        },
        // 被 @load调用的方法
        onLoad(e) { // 若加载条到了底部
            let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
                this.getList(); // 调用上面方法,请求数据
                this.pageNum++; // 分页数加一
                this.finished && clearTimeout(timer); //清除计时器
            }, 100);
        },
        // 加载失败调用方法
        onRefresh(e) {
            this.finished = false; // 清空列表数据
            this.loading = true; // 将 loading 设置为 true，表示处于加载状态
            this.pageNum = 1; // 分页数赋值为1
            this.list = []; // 清空数组
            this.onLoad(e); // 重新加载数据
        },
        toClientDetail(item) {
            sessionStorage.setItem('clientInfo', JSON.stringify(item))
            this.$router.push({
                name: "engineeringClicntDetailName",
            })
        },
        //前往巡检记录
        toPatrol(item) {
            sessionStorage.setItem('clientInfo', JSON.stringify(item))
            sessionStorage.setItem('fromRouterNameEngineering', 'engineeringConstructionName')
            this.$router.push({
                name: "engineeringPatrolListlName",
                query: {
                    isBack: true,
                }
            })
        },
        //前往工程进度
        toProjectPlan(item) {
            if (item.statusCode === '未开工') {
                return
            }
            sessionStorage.setItem('clientInfo', JSON.stringify(item))
            sessionStorage.setItem('fromRouterNameEngineering', 'engineeringConstructionName')
            this.$router.push({
                name: "engineeringProjectPlanName",
                query: {
                    isBack: true,
                }
            })
        },
        //施工进度状态列表
        async getProjectNodeList() {
            let obj = {
                Q_isDel_EQ: '0',
            }
            let res = await api.getProjectCustomStageList(obj)
            if (res.code == 200) {
                let temp = []
                if(res.data?.length){
                    res.data.forEach(e => {
                        let children = null
                        if(e.children?.length){
                            children = e.children.map(e1 => ({ ...e1, dictValue: e1.stageCode, dictLabel: e1.stageName  }))
                        }
                        temp.push({ ...e, dictValue: e.stageCode, dictLabel: e.stageName, children })
                    })
                }
                this.filterCondition[1].actions.push(...temp)
            }
        },
        //施工状态列表
        async getDictionariesList() {
            let obj = {
                Q_typeCode_IN: 'DICT322088',
            }
            let res = await api.getDictionariesList(obj)
            if (res.code == 200) {
                let temp = []
                res.data.forEach(e => {
                    temp.push({ value: e.code, text: e.name })
                })
                this.filterCondition[0].actions.push(...temp)
            }
        },
        //项目经理列表
        async getProjectManagerList() {
            let res = await api.getProjectManagerList()
            if (res.code == 200) {
                if (res.data.isArea === '1') {
                    const arr = res.data.list.flatMap(e => e?.projectId ? [{ value: e.projectId, text: e.projectName }] : [])
                    this.filterCondition[3].actions = arr
                } else {
                    this.filterCondition.splice(-1, 1)
                }
            }
        },
        // 调用拨号功能
        callPhone(phoneNo) {
            if (!phoneNo) return
            window.location.href = 'tel://' + phoneNo
        },
        //工地评分
        grade(item, index) {
            if (item.scoreValue) {
                return
            }
            this.clientDetails = item
            this.clientDetailsIndex = index
            this.isShowGrade = true
            this.$refs.gradePicker.setColumnIndex(0, 0);
        },
        //评分确认
        async gradeSure(item) {
            let scoreValue = this.$refs.gradePicker.getValues()[0]
            let obj = {
                id: this.clientDetails.id,
                scoreValue: scoreValue,
            }
            let res = await api.engineeringGrade(obj)
            if (res.code == 200) {
                this.list[this.clientDetailsIndex].scoreValue = scoreValue
                this.isShowGrade = false
            }
        },
        //权限判断
        permission(v) {
            return this.hasPermission(v)
        }
    },
    mounted() {
        this.getProjectNodeList() //施工进度列表
        this.getDictionariesList()//施工状态列表
        this.getProjectManagerList()//项目经理列表
    },
}
</script>

<style scoped lang="scss">
.construction-style {
    .sheet-top {
        position: relative;
        height: 45px;
        line-height: 45px;
        background: #F5F6F8;
        font-size: 16px;
        // font-family: Source Han Sans SC;
        font-weight: 400;


        .sheet-cancel {
            position: absolute;
            left: 28px;
            color: #999999;
        }

        .sheet-sure {
            position: absolute;
            right: 28px;
            color: #4F7AFD;
        }
    }

    .search-style {
        height: 56px;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        padding: 0 10px;

        .choosetime-input {
            height: 40px;
            line-height: 40px;
            background: #F5F6F8;
            border-radius: 6px;
            padding: 0;
            padding-right: 5px;
            font-size: 13px;

            ::v-deep .van-field__control {
                padding-left: 10px;
            }
        }

        .search-icon {
            width: 22px;
            height: 22px;
            margin: auto 0;
        }
    }

    .filter-style {
        height: 47px;
        background: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1px;

        .filter-item {
            width: 124px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #E6E6E6;

            .item-style {
                display: inline-block;
                // width: 50px;
                margin-right: 4px;
            }

            .icon-style {
                display: inline-block;
                // height: 40px;
                // line-height: 40px;
            }
        }

        .already-filter {
            color: #4F7AFD;
        }

        .content {
            padding-bottom: 81px;

            .sheet-top {
                position: relative;
                height: 45px;
                line-height: 45px;
                background: #F5F6F8;
                font-size: 16px;
                // font-family: Source Han Sans SC;
                font-weight: 400;


                .sheet-cancel {
                    position: absolute;
                    left: 28px;
                    color: #999999;
                }

                .sheet-sure {
                    position: absolute;
                    right: 28px;
                    color: #4F7AFD;
                }
            }

            .sheet-content {
                background: #FFFFFF;

                .sheet-item {
                    height: 45px;
                    line-height: 45px;
                    font-size: 16px;
                    // font-family: Source Han Sans SC;
                    font-weight: 400;
                    color: #333333;
                    text-align: left;
                    margin: 0 15.5px;
                    border-bottom: 1px solid #E6E6E6;
                    display: flex;
                    align-items: center;
                    position: relative;

                    .sheet-item-checked-icon {
                        width: 22px;
                        margin: auto 0;
                        position: absolute;
                        right: 11.5px;
                    }
                }
            }
        }
    }

    .content-style {
        padding: 0 10px;
        height: calc(100vh - 190px);
        overflow: auto;

        .item-style {
            background: #FFFFFF;
            padding: 8px 0 0 0;
            margin-top: 10px;
            border-radius: 6px;

            .top {
                padding: 0 10px;
                padding-bottom: 7px;
                display: flex;
                text-align: left;
                position: relative;
                align-items: center;
                border-bottom: 1px solid #E6E6E6;

                .tx-img {
                    width: 43px;
                    height: 43px;
                    margin-right: 11px;
                }

                .top-right {
                    .name-style {
                        height: 25px;
                        line-height: 25px;
                        font-size: 16px;
                        // font-family: Source Han Sans SC;
                        font-weight: 500;
                        color: #333333;
                        // margin-bottom: 10px;
                    }

                    .linkPhone-style {
                        // height: 11px;
                        // margin-top: 4px;
                        font-size: 11px;
                        font-family: Source Han Sans SC;
                        font-weight: 400;
                        color: #777777;
                    }
                }

                .jt-img {
                    width: 22px;
                    height: 22px;
                    position: absolute;
                    right: 13px;
                }
            }

            .center {
                padding: 10px;
                border-bottom: 1px solid #E6E6E6;
                text-align: left;

                .center-top div {
                    margin-right: 4px;
                    padding: 1px 2px;
                    // width: 126px;
                    display: inline-block;
                    height: 19px;
                    line-height: 19px;
                    background: #FFFFFF;
                    border: 1px solid #4F7AFD;
                    border-radius: 3px;
                    font-size: 10px;
                    // font-family: Source Han Sans SC;
                    font-weight: 400;
                    color: #4F7AFD;
                    margin-bottom: 10px;
                }

                .title {
                    margin-bottom: 7px;
                    font-size: 11px;
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    color: #777777;

                    .text {
                        color: #333333;
                    }
                }
            }

            .bottom {
                display: flex;
                align-items: center;
                height: 40px;

                // padding: 14px 24px;
                .bottom-item-style {
                    // height: 22px;
                    display: flex;
                    align-items: center;
                    border-right: 1px solid #E6E6E6;
                    width: 118px;
                    justify-content: center;

                    .text {
                        // line-height: 22px;
                        font-size: 13px;
                        font-family: Source Han Sans SC;
                        font-weight: 400;
                        color: #4F7AFD;
                    }

                    .text2 {
                        color: #40D673;
                    }

                    .text1{
                        color: #bbb;
                    }

                    img {
                        width: 22px;
                        height: 22px;
                    }
                }


            }
        }
    }

    ::v-deep .van-tabs__nav {
        background-color: #fff !important;
    }
    
    ::v-deep .van-tab {
        color: #323233 !important;
        background-color: #fff !important;
        border-radius: none !important;
        font-size: 14px !important;
    }
    
    ::v-deep .van-tab--active {
        color: #323233 !important;
        background-color: #fff !important;
    }
    
    ::v-deep .van-tabs__line {
        display: block !important;
    }
}
</style>
